import { TextField, Typography, withStyles, InputAdornment } from '@material-ui/core';
import { repeat } from 'lodash';
import React from 'react';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  values: {
    display: 'grid',
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(2),
  },
});

function MultifieldChecklistItemEditor({
  classes,
  readOnly = false,
  rootKey,
  columns,
  title,
  wasSubmit,
  value,
  onChange,
  checklistItems: checklistDefinition,
}) {
  return (
    <div className={classes.root}>
      <Typography variant="body1">{title}</Typography>
      <div className={classes.values} style={{ gridTemplateColumns: repeat('1fr ', columns) }}>
        {checklistDefinition.map(({ key, name, denominator }) => {
          const hasValue = value[key] !== null && value[key] !== undefined && value[key].trim() !== '';
          const valueInRange = hasValue && value[key] >= 0 && value[key] <= denominator;
          return (
            <TextField
              key={`${rootKey}.${key}`}
              type="number"
              error={wasSubmit && (!hasValue || !valueInRange)}
              helperText={wasSubmit && (!hasValue || !valueInRange) && `From 0 to ${denominator}`}
              InputProps={{ endAdornment: <InputAdornment position="end">/{denominator}</InputAdornment> }}
              value={value[key]}
              onChange={event => onChange(key, event.target.value)}
              inputProps={{ required: true, min: 0, max: denominator, readOnly }}
              label={name}
              autoComplete="off"
              id={`${rootKey}.${key}`}
              name={`${rootKey}.${key}`}
              margin="none"
              fullWidth
            />
          );
        })}
      </div>
    </div>
  );
}

export default withStyles(styles)(MultifieldChecklistItemEditor);
