import axios from 'axios';
import { getApiPathForType, getTitleForType } from 'components/checkup/utils';
import { upperFirst } from 'lodash';
import { actions, types } from 'reducers/entity/delete';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, put, takeLatest } from 'redux-saga/effects';

function* deleteEntity({ entityType, entity }) {
  const title = upperFirst(getTitleForType(entityType));
  try {
    const apiPath = getApiPathForType(entityType);
    yield call(axios.delete, `${apiPath}/${entity.id}`);

    yield put(actions.deleteSuccess(entityType, entity));
    yield put(snackActions.set(`${title} deleted`, snackVariants.success));
  } catch (e) {
    yield put(actions.deleteFailure(e));
    yield put(snackActions.set(`${title} delete failed, please try again later`, snackVariants.error));
  }
}

export default function* deleteSaga() {
  yield takeLatest(types.DELETE_REQUEST, deleteEntity);
}
