import auth from 'reducers/auth';
import checkup from 'reducers/checkup';
import configureAxiosInterceptors from 'reducers/configureAxiosInterceptors';
import dashboard from 'reducers/dashboard';
import entity from 'reducers/entity';
import inside from 'reducers/inside';
import network from 'reducers/network';
import profile from 'reducers/profile';
import snackbarNotification from 'reducers/snackbarNotification';
import user from 'reducers/user';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

const reducer = combineReducers({
  auth,
  profile,
  entity,
  snackbarNotification,
  network,
  dashboard,
  checkup,
  inside,
  user,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
configureAxiosInterceptors(store);

sagaMiddleware.run(rootSaga);

export default store;
