import React, { useMemo, useState } from 'react';
import { makeAutoObservable, observable, toJS } from 'mobx';
import axios from 'axios';
import { useDidMount } from 'react-hooks-lib';
import { Fab, makeStyles, Paper, Tooltip } from '@material-ui/core';
import SetTitle from 'components/common/SetTitle';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import TableToolbar from 'components/common/TableToolbar';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { GridRoot, TableContainer } from 'components/common/Grid';
import { SortingState } from '@devexpress/dx-react-grid';
import TableAutoLayout from 'components/common/TableAutoLayout';
import { momentUserTz } from 'components/common/momentUtils';
import { observer } from 'mobx-react';
import SSLineActionMenu from 'components/ssline/SSLineActionMenu';
import { find, remove, sortBy } from 'lodash';
import { ObserverEntityDeleter } from 'components/common/EntityDeleter';
import { TYPE_SSLINE } from 'components/checkup/utils';
import SSLineEdit from 'components/ssline/SSLineEdit';
import store from 'reducers';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { actions as authActions } from 'reducers/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  tableContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    minHeight: 200,
  },
  activationStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

class State {
  list = {
    loading: true,
    entities: [],
  };
  edit = {
    open: false,
    id: null,
  };
  delete = {
    currentRequestEntityId: null,
    loading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async initialize() {
    try {
      const response = await axios.get('/apigw/v1/ssline/all');
      this.list.entities = observable(sortBy(response.data, 'code'));
    } finally {
      this.list.loading = false;
    }
  }

  onEditRequest = (entity) => {
    this.edit.open = true;
    this.edit.id = entity?.id ?? null;
  };

  onDeleteRequest = (entity) => {
    this.delete.currentRequestEntityId = entity.id;
  };

  onDelete = async () => {
    try {
      this.delete.loading = true;
      await axios.delete(`/apigw/v1/ssline/${this.delete.currentRequestEntityId}`);
      remove(this.list.entities, ['id', this.delete.currentRequestEntityId]);
      this.list.entities = observable([...this.list.entities]);
      store.dispatch(authActions.updateSSLines(toJS(this.list.entities)));
      this.delete.currentRequestEntityId = null;
      store.dispatch(snackActions.set('SS line deleted', snackVariants.success));
    } catch (e) {
      store.dispatch(snackActions.set('Failed to delete SS line. Please try again later', snackVariants.error));
    } finally {
      this.delete.loading = false;
    }
  };

  onSaveSuccess = (entityId, entity, isNew) => {
    this.edit.open = false;
    if (isNew) {
      const updatedEntities = [...this.entities, entity];
      this.list.entities = observable(sortBy(updatedEntities, 'code'));
      store.dispatch(authActions.updateSSLines(toJS(this.list.entities)));
    } else {
      const entityToUpdate = find(this.list.entities, ['id', entityId]);
      entityToUpdate.fullName = entity.fullName;
      entityToUpdate.code = entity.code;
      entityToUpdate.createdAt = entity.createdAt;
      this.list.entities = observable([...this.list.entities]);
      store.dispatch(authActions.updateSSLines(toJS(this.list.entities)));
    }

    const message = isNew ? 'SS line created' : 'SS line updated';
    store.dispatch(snackActions.set(message, snackVariants.success));
  };

  get entityEdit() {
    return find(this.entities, ['id', this.edit.id]) ?? {};
  }

  get entities() {
    return this.list.entities;
  }

  get totalCount() {
    return this.list.entities?.length;
  }
}

const defaultSorting = [{ columnName: 'code', direction: 'asc' }];

function SSLineList() {
  const classes = useStyles();
  const [state] = useState(() => new State());
  useDidMount(() => {
    state.initialize();
  });
  const columns = useMemo(
    () => [
      {
        name: 'action',
        title: ' ',
        getCellValue: (row) => (
          <SSLineActionMenu entity={row} onEdit={state.onEditRequest} onDelete={state.onDeleteRequest} />
        ),
      },
      { name: 'code', title: 'Code' },
      { name: 'fullName', title: 'Name' },
      {
        name: 'createdAt',
        title: 'Created',
        getCellValue: (row) => <>{momentUserTz(row.createdAt).format('MM/DD/YYYY HH:mm')}</>,
      },
    ],
    [state]
  );

  return (
    <>
      <SetTitle title="SS Lines" />
      <ObserverEntityDeleter
        open={state.delete.currentRequestEntityId !== null}
        entityType={TYPE_SSLINE}
        entity={{}}
        isFetching={state.delete.loading}
        onClose={() => (state.delete.currentRequestEntityId = null)}
        onDelete={state.onDelete}
      />
      {state.edit.open && (
        <SSLineEdit
          isNew={state.edit.id === null}
          initialEntity={state.entityEdit}
          onClose={() => (state.edit.open = false)}
          onSaveSuccess={state.onSaveSuccess}
        />
      )}
      <Paper className={classes.root}>
        <div className={classes.content}>
          {state.list.loading && <BrandedLoadingIndicator />}
          {!state.list.loading && (
            <>
              <div className={classes.tableContainer}>
                <TableToolbar title={`SS lines${typeof state.totalCount === 'number' ? `: ${state.totalCount}` : ''}`}>
                  <Tooltip title="Create SS line">
                    <Fab
                      onClick={() => {
                        state.onEditRequest(null);
                      }}
                      size="small"
                      color="secondary"
                      aria-label="Create SS line"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </TableToolbar>
                <Grid rootComponent={GridRoot} rows={state.entities} columns={columns}>
                  <SortingState columnSortingEnabled={false} defaultSorting={defaultSorting} />
                  <Table containerComponent={TableContainer} tableComponent={TableAutoLayout} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </div>
            </>
          )}
        </div>
      </Paper>
    </>
  );
}

export default observer(SSLineList);
