import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckupWizard from 'components/add/CheckupWizard';
import { getTitleForType } from 'components/checkup/utils';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  actions,
  getEntity,
  getEntityType,
  getLoadError,
  getUpdateError,
  isLoadFetching,
  isOpen,
  isUpdateFetching,
} from 'reducers/checkup/edit';

const styles = theme => ({
  paperRoot: {
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 96px)',
      width: 600,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    flexGrow: 1,
    padding: 0,
  },
});

function CheckupEditor({
  classes,
  open,
  fullScreen,
  loadIsFetching,
  loadError,
  resetLoadError,
  updateIsFetching,
  updateError,
  resetUpdateError,
  entityType,
  entity,
  onSubmit,
  onClose,
}) {
  const title = entityType && getTitleForType(entityType);
  const onStepChange = useCallback(() => {
    const paper = document.getElementById('checkup-editor-dialog-paper');
    paper && paper.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Dialog
        PaperProps={{
          id: 'checkup-editor-dialog-paper',
          className: classes.paperRoot,
        }}
        open={open}
        fullScreen={fullScreen}
        aria-labelledby="checkup-editor-dialog-title"
      >
        <DialogTitle id="checkup-editor-dialog-title" className={classes.title} disableTypography>
          <Typography variant="h6">Edit {title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {loadIsFetching && <BrandedLoadingIndicator />}
          {!loadIsFetching && entity && (
            <CheckupWizard
              entityType={entityType}
              initialEntity={entity.toJS()}
              isFetching={updateIsFetching}
              error={updateError}
              onSubmit={onSubmit}
              resetError={resetUpdateError}
              onStepChange={onStepChange}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={loadError}
        onClose={resetLoadError}
        aria-labelledby="checkup-editor-load-dialog-title"
        aria-describedby="checkup-editor-load-dialog-description"
      >
        <DialogTitle id="checkup-editor-load-dialog-title">Edit failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="checkup-editor-load-dialog-description">
            Failed to load {title} for edit, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetLoadError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isOpen(state),
    loadError: getLoadError(state),
    loadIsFetching: isLoadFetching(state),
    updateIsFetching: isUpdateFetching(state),
    updateError: getUpdateError(state),
    entityType: getEntityType(state),
    entity: getEntity(state),
  };
}

export default connect(
  mapStateToProps,
  {
    onClose: actions.close,
    resetLoadError: actions.resetLoadError,
    resetUpdateError: actions.resetUpdateError,
    onSubmit: actions.updateRequest,
  }
)(withMobileDialog()(withStyles(styles)(CheckupEditor)));
