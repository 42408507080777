import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { Grid, Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    minHeight: 48,
  },
  label: {
    height: 16,
  },
  labelTypography: {
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    lineHeight: 1,
    fontSize: '1rem',
  },
  value: {
    minHeight: 32,
  },
});

function StaticTextField({ classes, label, value }) {
  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid item container justify="flex-start" className={classes.label}>
        <Typography variant="caption" color="textSecondary" component="div" className={classes.labelTypography}>
          {label}
        </Typography>
      </Grid>
      <Grid item container className={classes.value}>
        <Typography variant="body1" color="textPrimary" component="div">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(StaticTextField);
