import * as api from 'api/auth';
import { actions, types } from 'reducers/auth';
import { call, put, takeLatest } from 'redux-saga/effects';

function* refreshCsrfCookie() {
  try {
    yield call(api.getUserContext);
  } catch (e) {
    // ignore
  }
}

function* login(action) {
  try {
    yield refreshCsrfCookie();
    const data = { username: action.email, password: action.password, rememberMe: action.rememberMe };
    yield call(api.login, data);

    const response = yield call(api.getUserContext);
    yield put(actions.loginSuccess(response.data));
  } catch (e) {
    yield put(actions.loginFailure(e));
  }
}

function* logout() {
  try {
    yield call(api.logout);
    yield refreshCsrfCookie();
  } catch (e) {
    // ignore
  }
}

export default function* auth() {
  yield takeLatest(types.LOGIN_REQUEST, login);
  yield takeLatest(types.LOGOUT, logout);
}
