import { Button, withStyles } from '@material-ui/core';
import SetTitle from 'components/common/SetTitle';
import React from 'react';
import { FaSignInAlt as CheckInIcon, FaSignOutAlt as CheckOutIcon } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
    marginRight: theme.spacing(3),
  },
  button: {
    width: 240,
    color: `${theme.palette.secondary.contrastText} !important`,
  },
});

function AddMovement({ classes }) {
  return (
    <div className={classes.root}>
      <SetTitle title="Add movement" />
      <Button
        component={Link}
        to="/add/check-in"
        variant="contained"
        size="large"
        color="secondary"
        className={classes.button}
      >
        <CheckInIcon className={classes.icon} />
        Check in
      </Button>
      <Button
        component={Link}
        to="/add/check-out"
        variant="contained"
        size="large"
        color="secondary"
        className={classes.button}
      >
        <CheckOutIcon className={classes.icon} />
        Check out
      </Button>
    </div>
  );
}

export default withStyles(styles)(AddMovement);
