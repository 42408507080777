import ChecklistSuccessfulIcon from '@material-ui/icons/DoneAll';
import ChecklistIcon from '@material-ui/icons/ListAlt';
import ChecklistFailedIcon from '@material-ui/icons/Warning';
import { chartAspectRatio } from 'components/dashboard/DashboardWidgets';
import TwoSidesWidgetHeader from 'components/dashboard/TwoSidesWidgetHeader';
import { setItemStyleColor } from 'components/dashboard/utils';
import Widget from 'components/dashboard/Widget';
import WidgetChart from 'components/dashboard/WidgetChart';
import COLORS from 'components/style/colors';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

const noFailuresColor = COLORS.GREEN;
const hasFailuresColor = COLORS.DARK_YELLOW;

function ChecklistStatsWidget({ value, valueKey, isFetching, title }) {
  const noFailures = value && value[`${valueKey}NoIssues`];
  const hasFailures = value && value[`${valueKey}WithIssues`];
  const data =
    value &&
    setItemStyleColor([
      { name: 'No issues', value: noFailures, color: noFailuresColor, renderIcon: () => <ChecklistSuccessfulIcon /> },
      { name: 'With issues', value: hasFailures, color: hasFailuresColor, renderIcon: () => <ChecklistFailedIcon /> },
    ]);

  return (
    <Widget icon={ChecklistIcon} title={title}>
      <>
        <TwoSidesWidgetHeader
          left={{ name: 'No issues', value: noFailures, color: noFailuresColor }}
          right={{ name: 'With issues', value: hasFailures, color: hasFailuresColor }}
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / chartAspectRatio;

            const options = {
              tooltip: {
                trigger: 'item',
                axisPointer: {
                  type: 'shadow',
                },
              },
              legend: {
                show: false,
              },
              toolbox: {
                show: false,
              },
              series: data && {
                type: 'pie',
                radius: ['40%', '80%'],
                center: [width / 2, height / 2],
                itemStyle: { borderWidth: 2, borderColor: 'white' },
                label: {
                  normal: {
                    show: false,
                  },
                },
                data,
              },
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

export default React.memo(ChecklistStatsWidget);
