import { amber, green, red } from '@material-ui/core/colors';

const COLORS = Object.freeze({
  BLUE: '#3c6fff',
  LIGHT_BLUE: '#65c7f6',
  GREEN: '#33c982',
  DARK_YELLOW: '#fcb700',
  DARK_GREY: '#6a798e',
  LIGHT_GREY: '#b3bbc6',
});

export const SUCCESS_COLOR = green[600];
export const WARN_COLOR = amber[700];
export const ERROR_COLOR = red[700];
export const PRIMARY_DARK_COLOR = '#1f2018';

export default COLORS;
