import { LinearProgress, withStyles } from '@material-ui/core';
import logo from 'components/media/logo_white.svg';
import React, { useEffect, useState } from 'react';
import { PRIMARY_DARK_COLOR } from 'components/style/colors';

const styles = () => ({
  root: {
    background: `radial-gradient(circle, #00004B 0%, ${PRIMARY_DARK_COLOR} 75%)`,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  logo: {
    width: 280,
  },
  progress: {
    width: 280,
    backgroundColor: 'white',
  },
});

function SplashScreen({ classes }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(id);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />
      <LinearProgress color="primary" className={classes.progress} />
    </div>
  );
}

export default withStyles(styles)(React.memo(SplashScreen));
