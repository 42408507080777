import { getContainerVisitStatus } from 'components/checkup/utils';
import { fromJS } from 'immutable';

export const types = {
  SEARCH_REQUEST: 'INSIDE/CONTAINERS/SEARCH_REQUEST',
  SEARCH_SUCCESS: 'INSIDE/CONTAINERS/SEARCH_SUCCESS',
  SEARCH_FAILURE: 'INSIDE/CONTAINERS/SEARCH_FAILURE',
  RESET_ERROR: 'INSIDE/CONTAINERS/RESET_ERROR',
  RESET: 'INSIDE/CONTAINERS/RESET',
  EXPORT_REQUEST: 'INSIDE/CONTAINERS/EXPORT_REQUEST',
  EXPORT_SUCCESS: 'INSIDE/CONTAINERS/EXPORT_SUCCESS',
  EXPORT_FAILURE: 'INSIDE/CONTAINERS/EXPORT_FAILURE',
};

function prepareItems(items = []) {
  return items.filter((item) => getContainerVisitStatus(item) === 'Open');
}

export const initialState = fromJS({
  items: null,
  isFetching: false,
  exportIsFetching: false,
  error: false,
});

export default function containers(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH_REQUEST:
      return state.withMutations((s) => {
        s.set('isFetching', true);
      });
    case types.SEARCH_SUCCESS:
      return state.withMutations((s) => {
        const { items } = action;
        s.set('isFetching', false);
        s.set('items', fromJS(prepareItems(items)));
      });
    case types.SEARCH_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('items', null);
        s.set('error', true);
      });
    case types.RESET_ERROR:
      return state.set('error', false);
    case types.RESET:
      return initialState;
    case types.EXPORT_REQUEST:
      return state.set('exportIsFetching', true);
    case types.EXPORT_SUCCESS:
      return state.set('exportIsFetching', false);
    case types.EXPORT_FAILURE:
      return state.set('exportIsFetching', false);
    default:
      return state;
  }
}

export const actions = {
  searchRequest: (yardId, query) => ({ type: types.SEARCH_REQUEST, yardId, query }),
  searchSuccess: (items) => ({ type: types.SEARCH_SUCCESS, items }),
  searchFailure: (error) => ({ type: types.SEARCH_FAILURE, error }),
  resetError: () => ({ type: types.RESET_ERROR }),
  reset: () => ({ type: types.RESET }),
  exportRequest: (yardId, query, filename) => ({
    type: types.EXPORT_REQUEST,
    yardId,
    query,
    filename,
  }),
  exportSuccess: () => ({ type: types.EXPORT_SUCCESS }),
  exportFailure: (error) => ({ type: types.EXPORT_FAILURE, error }),
};

export const getRoot = (state) => state.get('inside').get('containers');
export const getItems = (state) => getRoot(state).get('items');
export const getIsFetching = (state) => getRoot(state).get('isFetching');
export const getExportIsFetching = (state) => getRoot(state).get('exportIsFetching');
export const getError = (state) => getRoot(state).get('error');
