import { CircularProgress, Fab, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React from 'react';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  fab: {
    height: 64,
    width: 64,
    backgroundColor: 'rgba(0,0,0, 0)',
    boxShadow: 'none',
    border: '2px solid #fff',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, 0)',
    },
  },
  icon: {
    fontSize: 36,
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

function FlatButton({ classes, icon: IconTag, inProgress = false, ...rest }) {
  return (
    <div className={classes.root}>
      <Fab className={classes.fab} {...rest}>
        <IconTag color="inherit" className={classes.icon} />
      </Fab>
      {inProgress && <CircularProgress size={76} className={classes.progress} />}
    </div>
  );
}

export default withStyles(styles)(FlatButton);
