import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { CircularProgress, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    userSelect: 'none',
    justifyContent: 'inherit',
  },
  progress: {
    marginRight: theme.spacing(3),
  },
});

function LoadingIndicator({ classes, children, ...rest }) {
  return (
    <Grid className={classes.root} component="span" container wrap="nowrap" alignItems="center">
      <CircularProgress variant="indeterminate" className={classes.progress} size={24} color="inherit" {...rest} />
      {children}
    </Grid>
  );
}

export default withStyles(styles)(LoadingIndicator);
