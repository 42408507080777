import { Tooltip, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import React from 'react';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
});

const roles = {
  guard: {
    label: 'Guard',
    tooltip:
      'Guards can only add new movements, without an ability to see existing ones. Only one yard can be assigned to a single guard.',
  },
  supervisor: {
    label: 'Supervisor',
    tooltip:
      'Supervisors, for the yards assigned to them, can add new movements and also see existing ones in a read-only mode.',
  },
  manager: {
    label: 'Manager',
    tooltip:
      'Managers have full control (create, edit, delete) over movements and users for the yards assigned to them.',
  },
  superadmin: {
    label: 'Administrator',
  },
};

function RoleWithTooltip({ classes, value }) {
  const role = roles[value];
  if (!role) {
    return null;
  }

  const { label, tooltip } = role;
  return (
    <span className={classes.root}>
      {label}
      {tooltip && (
        <Tooltip interactive title={tooltip}>
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      )}
    </span>
  );
}

export default withStyles(styles)(RoleWithTooltip);
