import { withStyles } from '@material-ui/core';
import CheckupCreator from 'components/checkup/CheckupCreator';
import { TYPE_CHECK_OUT } from 'components/checkup/utils';
import React from 'react';

const styles = () => ({});

function CheckOutWizard() {
  return <CheckupCreator entityType={TYPE_CHECK_OUT} />;
}

export default withStyles(styles)(CheckOutWizard);
