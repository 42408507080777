import CheckupWizard from 'components/add/CheckupWizard';
import { getTitleForType } from 'components/checkup/utils';
import SetTitle from 'components/common/SetTitle';
import { upperFirst } from 'lodash';
import React, { useCallback } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { actions, getAlreadyInside, getError, getIsFetching } from 'reducers/checkup/create';

function CheckupCreator({ entityType, isFetching, alreadyInside, error, resetError, onSubmit, onUnmount }) {
  useWillUnmount(onUnmount);
  const onStepChange = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = upperFirst(getTitleForType(entityType));
  return (
    <>
      <SetTitle title={title} />
      <CheckupWizard
        entityType={entityType}
        isFetching={isFetching}
        alreadyInside={alreadyInside}
        error={error}
        onSubmit={onSubmit}
        resetError={resetError}
        onStepChange={onStepChange}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: getIsFetching(state),
    error: getError(state),
    alreadyInside: getAlreadyInside(state),
  };
}

export default connect(mapStateToProps, {
  onUnmount: actions.reset,
  resetError: actions.resetError,
  onSubmit: actions.createRequest,
})(CheckupCreator);
