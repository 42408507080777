import { momentUserTz } from 'components/common/momentUtils';
import useQueryParam from 'components/common/useQueryParam';

export const dateFormat = 'MM/DD/YYYY';
// April 1, 2019 - we can't have any data prior to the project launch
export const minDate = '04/01/2019';

export default function useDateRange(location, history) {
  const [endDate, setEndDate] = useQueryParam(
    'ed',
    (value) => {
      let date = value || momentUserTz().format(dateFormat);
      if (momentUserTz(date, dateFormat).isBefore(momentUserTz(minDate, dateFormat), 'day')) {
        date = minDate;
      }
      return date;
    },
    location,
    history
  );
  const [startDate, setStartDate] = useQueryParam(
    'sd',
    (value) => {
      let date;
      if (value) {
        date = momentUserTz(value, dateFormat).isAfter(momentUserTz(endDate, dateFormat), 'day') ? endDate : value;
      } else {
        date = momentUserTz(momentUserTz(endDate, dateFormat)).subtract(30, 'days').format(dateFormat);
      }
      if (momentUserTz(date, dateFormat).isBefore(momentUserTz(minDate, dateFormat), 'day')) {
        date = minDate;
      }
      return date;
    },
    location,
    history
  );

  return [startDate, setStartDate, endDate, setEndDate];
}
