import { FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import { fromJS } from 'immutable';
import { cloneDeep, compact, keyBy, sortBy, uniqBy } from 'lodash';
import { types as networkTypes } from 'reducers/network';
import { getErrorResponseStatus } from 'reducers/utils';

export const types = {
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',
  LOGOUT: 'AUTH/LOGOUT',
  USER_CONTEXT_UPDATED: 'AUTH/USER_CONTEXT_UPDATED',
  RESET_FLAGS: 'AUTH/RESET_FLAGS',
  UPDATE_SS_LINES: 'AUTH/UPDATE_SS_LINES',
};

export const initialState = fromJS({
  userContext: null,
  isFetching: false,
  error: null,
});

function prepareUserContext(userContext) {
  const { ssLines = [] } = userContext;
  const { yards = [] } = userContext;
  userContext.ssLines = keyBy(ssLines, 'code');
  userContext.yards = keyBy(yards, 'id');
  userContext.yardCompanies = sortBy(uniqBy(compact(yards.map((yard) => yard.company)), 'id'), (company) =>
    company.name.toLowerCase()
  );
  return fromJS(userContext);
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return state.withMutations((s) => s.set('isFetching', true).set('error', null));
    case types.LOGIN_SUCCESS:
      return state.withMutations((s) =>
        s.set('isFetching', false).set('userContext', prepareUserContext(action.userContext))
      );
    case types.LOGIN_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        if (getErrorResponseStatus(action) === UNAUTHORIZED) {
          s.set('error', 'Incorrect email or password');
        }
      });
    case types.LOGOUT:
      return state.set('userContext', null);
    case types.USER_CONTEXT_UPDATED:
      return state.set('userContext', prepareUserContext(action.userContext));
    case types.RESET_FLAGS:
      return state.withMutations((s) => s.set('isFetching', false).set('error', null));
    case networkTypes.REQUEST_FAILURE: {
      const status = getErrorResponseStatus(action);
      const isFetching = state.get('isFetching');
      if (!isFetching && (status === UNAUTHORIZED || status === FORBIDDEN)) {
        return initialState;
      }
      return state;
    }
    case types.UPDATE_SS_LINES: {
      const ssLines = cloneDeep(action.ssLines);
      ssLines.forEach((ssLine) => {
        if (!ssLine.name) {
          ssLine.name = ssLine.fullName;
        }
      });
      return state.withMutations((s) => s.setIn(['userContext', 'ssLines'], fromJS(keyBy(ssLines, 'code'))));
    }
    default:
      return state;
  }
}

export const actions = {
  loginRequest: (email, password, rememberMe) => ({ type: types.LOGIN_REQUEST, email, password, rememberMe }),
  loginSuccess: (userContext) => ({ type: types.LOGIN_SUCCESS, userContext }),
  loginFailure: (error) => ({ type: types.LOGIN_FAILURE, error }),
  logout: () => ({ type: types.LOGOUT }),
  userContextUpdated: (userContext) => ({ type: types.USER_CONTEXT_UPDATED, userContext }),
  resetFlags: () => ({ type: types.RESET_FLAGS }),
  updateSSLines: (ssLines) => ({ type: types.UPDATE_SS_LINES, ssLines }),
};

export const getAuth = (state) => state.get('auth');
export const getUserContext = (state) => getAuth(state).get('userContext');
export const isAuthenticated = (state) => getUserContext(state) !== null;
export const isImpersonation = (state) =>
  isAuthenticated(state) && getUserContext(state).get('originalUser') !== undefined;
export const isSuperAdmin = (state) => (getUserContext(state) ? getUserContext(state).get('role') === null : false);
export const getUserEmail = (state) => (getUserContext(state) ? getUserContext(state).get('email') : null);
export const getUserId = (state) => (getUserContext(state) ? getUserContext(state).get('id') : null);
export const getUserName = (state) => (getUserContext(state) ? getUserContext(state).get('name') : null);
export const getUserCompany = (state) => (getUserContext(state) ? getUserContext(state).get('company') : null);
export const getUserTimezone = (state) => (getUserContext(state) ? getUserContext(state).get('timezone') : null);
export const getUserRole = (state) => (getUserContext(state) ? getUserContext(state).get('role') : null);
export const getUserRoleName = (state) => (getUserRole(state) ? getUserRole(state).get('name') : null);
export const getSSLines = (state) => (getUserContext(state) ? getUserContext(state).get('ssLines') : null);
export const geSSLineByCode = (state, code) => (getSSLines(state) ? getSSLines(state).get(code.toUpperCase()) : null);
export const getYards = (state) => (getUserContext(state) ? getUserContext(state).get('yards') : null);
export const getDefaultYardId = (state) => {
  const yards = getYards(state);
  if (!yards || yards.isEmpty()) {
    return null;
  }

  const lastUsed = localStorage.getItem('lastUsedYardId');
  if (lastUsed && yards.has(lastUsed)) {
    return lastUsed;
  }
  return yards.first().get('id');
};
export const getYardById = (state, id) => (getYards(state) ? getYards(state).get(id) : null);
export const getYardNameById = (state, id) => (getYardById(state, id) ? getYardById(state, id).get('name') : null);
export const isChecklistRequiredForYard = (state, yardId) => {
  const yard = getYardById(state, yardId);
  return yard ? yard.get('requireChecklists', true) : true;
};
export const getYardCompanies = (state) => (getUserContext(state) ? getUserContext(state).get('yardCompanies') : null);
