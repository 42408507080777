import { withStyles, Button, Snackbar } from '@material-ui/core';
import RetryIcon from '@material-ui/icons/Refresh';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import { DRAWER_WIDTH } from 'components/menu/PrimaryNavigation';
import SnackbarContentWrapper from 'components/snackbar/SnackbarContentWrapper';
import React from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from 'reducers/auth';
import { actions, getConnectionCheckIsFetching, getIsOffline } from 'reducers/network';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${DRAWER_WIDTH / 2}px)`,
    },
  },
  rootAnonymous: {
    [theme.breakpoints.up('md')]: {
      left: 'calc(50% + 240px)',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 650,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

function SnackbarOfflineStatus({ classes, authenticated, isOffline, isFetching, onRetryClick }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOffline}
      className={authenticated ? classes.root : classes.rootAnonymous}
    >
      <SnackbarContentWrapper
        className={classes.content}
        action={
          <Button color="inherit" onClick={onRetryClick}>
            <AsyncActionLabel
              isFetching={isFetching}
              label={
                <>
                  <RetryIcon className={classes.icon} />
                  Reconnect
                </>
              }
              fetchingLabel="Reconnecting..."
            />
          </Button>
        }
        variant="warning"
        message="You are offline or the server is temporary unavailable"
      />
    </Snackbar>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
    isOffline: getIsOffline(state),
    isFetching: getConnectionCheckIsFetching(state),
  };
}

export default connect(mapStateToProps, { onRetryClick: actions.connectionCheckRequest })(
  withStyles(styles)(SnackbarOfflineStatus)
);
