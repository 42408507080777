import { Divider, IconButton, InputBase, makeStyles, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import useQueryParam from 'components/common/useQueryParam';
import React, { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  search: {
    maxWidth: 800,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: 'none',
    height: 40,
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  searchIcon: {
    cursor: 'pointer',
    width: theme.spacing(9),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

function SearchBox({ placeholder = '', helperText, location, history, onSearch }) {
  const classes = useStyles();
  const [query, setQuery] = useQueryParam('query', v => v || '', location, history);
  const [inputQuery, setInputQuery] = useState(query);
  useEffect(() => setInputQuery(query), [query]);
  const handleSearch = useCallback(
    query => {
      setQuery(query);
      onSearch(query);
    },
    [setQuery, onSearch]
  );
  const onChange = useCallback(e => setInputQuery(e.target.value), []);
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      handleSearch(inputQuery);
    },
    [handleSearch, inputQuery]
  );

  return (
    <form onSubmit={onSubmit} className={classes.search}>
      {helperText && (
        <Tooltip interactive title={helperText} enterDelay={300}>
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      )}
      <InputBase
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        id="searchBox"
        key="searchBox"
        inputProps={{
          'aria-label': 'Search query',
        }}
        className={classes.input}
        placeholder={placeholder}
        value={inputQuery}
        onChange={onChange}
        type="search"
      />
      <Divider className={classes.divider} />
      <IconButton onClick={() => handleSearch(inputQuery)} className={classes.iconButton} aria-label="Search">
        <SearchIcon />
      </IconButton>
    </form>
  );
}

export default SearchBox;
