import { Radio, Typography, withStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import React from 'react';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  itemHeader: {
    flexGrow: 1,
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
  radioHeader: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > *:nth-child(2)': {
      marginRight: theme.spacing(1),
    },
  },
  itemName: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    paddingTop: 12,
  },
  yes: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  no: {
    color: red[600],
    '&$checked': {
      color: red[500],
    },
  },
  checked: {},
});

function ChecklistEditor({ classes, value, onChange, checklistItems: checklistDefinition, required = false }) {
  return (
    <div className={classes.root}>
      <div className={classes.listItem}>
        <Typography className={classes.itemHeader} variant="body1" color="textSecondary">
          Item
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.radioHeader}>
          Yes
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.radioHeader}>
          No
        </Typography>
      </div>
      {checklistDefinition.map(({ key, name }) => {
        const hasValue = value[key] !== undefined && value[key] !== null;
        return (
          <div key={key} className={classes.listItem}>
            <Typography
              className={classes.itemName}
              variant="body1"
              style={{ color: required && !hasValue && red[600] }}
            >
              {name}
            </Typography>
            <Radio
              checked={value[key] === true}
              onChange={event => onChange(key, true)}
              classes={{
                root: classes.yes,
                checked: classes.checked,
              }}
            />
            <Radio
              checked={value[key] === false}
              onChange={event => onChange(key, false)}
              classes={{
                root: classes.no,
                checked: classes.checked,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default withStyles(styles)(ChecklistEditor);
