import { SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Link as MuiLink,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { noValue } from 'components/checkup/utils';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import { GridRoot, TableContainer } from 'components/common/Grid';
import InlineLoadingIndicator from 'components/common/InlineLoadingIndicator';
import { momentUserTz } from 'components/common/momentUtils';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import TableAutoLayout from 'components/common/TableAutoLayout';
import TableToolbar from 'components/common/TableToolbar';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import UserActionMenu from 'components/user/UserActionMenu';
import UserSearchHelperText from 'components/user/UserSearchHelperText';
import { capitalize, get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { useDidMount, useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { getUserRoleName } from 'reducers/auth';
import { actions as userEditActions } from 'reducers/user/edit';
import {
  actions,
  getError,
  getIsFetching,
  getIsFetchingToAppend,
  getItems,
  getTotalMatchedCount,
} from 'reducers/user/list';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  tableContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    minHeight: 200,
  },
  activationStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const defaultSorting = [{ columnName: 'createdAt', direction: 'desc' }];

function UserList({
  location,
  history,
  searchRequest,
  onUnmount,
  userRole,
  onCreateClick,
  isFetching,
  isFetchingToAppend,
  totalMatched,
  error,
  onResetError,
  value,
}) {
  const classes = useStyles();
  useWillUnmount(onUnmount);
  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const handleSearch = useCallback((value) => searchRequest(value, false), [searchRequest]);
  useDidMount(() => {
    handleSearch(query);
  });
  const columns = useMemo(
    () => [
      { name: 'action', title: ' ', getCellValue: (row) => <UserActionMenu entity={row} /> },
      { name: 'name', title: 'Name' },
      { name: 'email', title: 'Email' },
      { name: 'company', title: 'Company', getCellValue: (row) => get(row, 'company.name', noValue) },
      {
        name: 'role',
        title: 'Role',
        getCellValue: (row) => (row.superAdmin ? 'Administrator' : capitalize(row.role)),
      },
      {
        name: 'yards',
        title: 'Yards',
        getCellValue: (row) =>
          row.yards?.length ? row.yards.map(({ id, name }) => <div key={id}>{name}</div>) : noValue,
      },
      {
        name: 'activation',
        title: 'Activation',
        getCellValue: (row) =>
          row.active ? (
            'Active'
          ) : (
            <Tooltip
              interactive
              title={
                <>
                  To activate the account user needs to follow activation link sent to his email. The link is valid 24
                  hours from the creation date. To activate the account after this period user needs to follow the{' '}
                  <MuiLink to={`/forgot-password`} target="_blank" rel="noopener noreferrer" component={Link}>
                    Reset password
                  </MuiLink>{' '}
                  procedure.
                </>
              }
            >
              <span className={classes.activationStatus}>
                Pending
                <HelpIcon className={classes.helpIcon} />
              </span>
            </Tooltip>
          ),
      },
      {
        name: 'createdAt',
        title: 'Created',
        getCellValue: (row) => (
          <>
            {momentUserTz(row.createdAt).format('MM/DD/YYYY HH:mm')}
            {!userRole && row.portalLastLoginAt && (
              <>
                <br />
                <Tooltip
                  placement="bottom-start"
                  title={`Portal last login at ${momentUserTz(row.portalLastLoginAt).format('LL, LTS')}`}
                  enterDelay={300}
                >
                  <Typography component="span" color="textSecondary" variant="caption">
                    Last login: {moment.min(momentUserTz(row.portalLastLoginAt), momentUserTz()).fromNow()}
                  </Typography>
                </Tooltip>
              </>
            )}
          </>
        ),
      },
    ],
    [classes, userRole]
  );

  const displayTable = (isFetching && isFetchingToAppend) || !isFetching;

  return (
    <>
      <SetTitle title="Users" />
      <Dialog
        open={error}
        onClose={onResetError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Load failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to load users, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.searchBoxContainer}>
        <SearchBox
          helperText={<UserSearchHelperText />}
          location={location}
          history={history}
          placeholder="Search by name, email, etc."
          onSearch={handleSearch}
        />
      </div>
      <Paper className={classes.root}>
        <div className={classes.content}>
          {isFetching && !isFetchingToAppend && <BrandedLoadingIndicator />}
          {displayTable && (
            <>
              <div className={classes.tableContainer}>
                <TableToolbar title={`Users${typeof totalMatched === 'number' ? `: ${totalMatched}` : ''}`}>
                  <Tooltip title="Create user">
                    <Fab onClick={() => onCreateClick()} size="small" color="secondary" aria-label="Create user">
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </TableToolbar>
                <Grid rootComponent={GridRoot} rows={value} columns={columns}>
                  <SortingState columnSortingEnabled={false} defaultSorting={defaultSorting} />
                  <Table containerComponent={TableContainer} tableComponent={TableAutoLayout} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </div>
              {!isFetching && totalMatched > 0 && value && value.length < totalMatched && (
                <Waypoint
                  scrollableAncestor={window}
                  bottomOffset="-500px"
                  onEnter={() => searchRequest(query, true)}
                />
              )}
              {isFetching && isFetchingToAppend && <InlineLoadingIndicator />}
            </>
          )}
        </div>
      </Paper>
    </>
  );
}

function mapStateToProps(state) {
  return {
    value: getItems(state) || [],
    isFetching: getIsFetching(state),
    isFetchingToAppend: getIsFetchingToAppend(state),
    totalMatched: getTotalMatchedCount(state),
    error: getError(state),
    userRole: getUserRoleName(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    onResetError: actions.resetError,
    searchRequest: actions.searchRequest,
    onUnmount: actions.reset,
    onCreateClick: userEditActions.createRequest,
  })(toJS(UserList))
);
