import { withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { WARN_COLOR } from 'components/style/colors';
import React from 'react';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: WARN_COLOR,
  },
  textWithIcon: {
    position: 'relative',
    top: '-2px',
  },
});

function SSLineName({ classes, value }) {
  const isUnknown = value === 'Unknown';
  return (
    <span className={classes.root}>
      {isUnknown && <WarningIcon className={classes.icon} />}
      <span className={isUnknown ? classes.textWithIcon : null}>
        <strong>{value}</strong>&nbsp;SS Line
      </span>
    </span>
  );
}

export default withStyles(styles)(SSLineName);
