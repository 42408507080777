import { saveResponseAsFile } from 'api/utils';
import axios from 'axios';
import { checkInApiPath } from 'components/checkup/utils';
import { getUserTimezone } from 'components/common/momentUtils';
import { actions, types } from 'reducers/inside/containers';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

function prepareSearchParams(yardId, query) {
  const params = { query, limit: 9999, sort: [{ name: 'createdAt', order: 'ASC' }] };
  params.filter = {
    and: {
      items: [
        { term: { name: 'yardId', value: yardId } },
        {
          term: {
            name: 'containerInside',
            value: true,
          },
        },
      ],
    },
  };
  return params;
}

function* exportContainers({ yardId, query, filename }) {
  try {
    const searchParams = prepareSearchParams(yardId, query);
    const attributes = {
      yardId,
      timezone: getUserTimezone(),
      mode: 'containersInside',
    };

    const response = yield call(axios.post, `/apigw/v1/checkin/actions/export/invoke`, { searchParams, attributes });
    saveResponseAsFile(response, filename);
    yield put(actions.exportSuccess());
  } catch (e) {
    yield put(actions.exportFailure(e));
    yield put(snackActions.set('Failed to export data as CSV', snackVariants.error));
  }
}

function* searchContainers({ yardId, query }) {
  try {
    const params = prepareSearchParams(yardId, query);
    const response = yield call(axios.post, `${checkInApiPath}/search`, params);
    yield put(actions.searchSuccess(response.data.items));
  } catch (e) {
    yield put(actions.searchFailure(e));
  }
}

export default function* containers() {
  yield all([takeLatest(types.SEARCH_REQUEST, searchContainers), takeEvery(types.EXPORT_REQUEST, exportContainers)]);
}
