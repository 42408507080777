import { fromJS } from 'immutable';

export const types = {
  LOAD_REQUEST: 'CHECKUP_EDIT/LOAD_REQUEST',
  LOAD_SUCCESS: 'CHECKUP_EDIT/LOAD_SUCCESS',
  LOAD_FAILURE: 'CHECKUP_EDIT/LOAD_FAILURE',
  UPDATE_REQUEST: 'CHECKUP_EDIT/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CHECKUP_EDIT/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CHECKUP_EDIT/UPDATE_FAILURE',
  RESET_LOAD_ERROR: 'CHECKUP_EDIT/RESET_LOAD_ERROR',
  RESET_UPDATE_ERROR: 'CHECKUP_EDIT/RESET_UPDATE_ERROR',
  CLOSE: 'CHECKUP_EDIT/CLOSE',
};

export const initialState = fromJS({
  open: false,
  loadIsFetching: false,
  loadError: false,
  updateIsFetching: false,
  updateError: false,
  entityId: null,
  entityType: null,
  entity: null,
});

export default function edit(state = initialState, action) {
  const { type, entityId, entityType, entity } = action;
  switch (type) {
    case types.LOAD_REQUEST:
      return state.withMutations(s => {
        s.set('open', true);
        s.set('loadIsFetching', true);
        s.set('updateIsFetching', false);
        s.set('entityId', entityId);
        s.set('entityType', entityType);
        s.set('entity', null);
        s.set('loadError', false);
        s.set('updateError', false);
      });
    case types.LOAD_SUCCESS:
      return state.withMutations(s => {
        s.set('loadIsFetching', false);
        s.set('entity', fromJS(entity));
      });
    case types.LOAD_FAILURE:
      return state.withMutations(s => {
        s.set('open', false);
        s.set('loadIsFetching', false);
        s.set('loadError', true);
      });
    case types.UPDATE_REQUEST:
      return state.withMutations(s => {
        s.set('updateIsFetching', true);
        s.set('updateError', false);
      });
    case types.UPDATE_SUCCESS:
      return state.withMutations(s => {
        s.set('open', false);
        s.set('updateIsFetching', false);
        s.set('updateError', false);
      });
    case types.UPDATE_FAILURE:
      return state.withMutations(s => {
        s.set('updateIsFetching', false);
        s.set('updateError', true);
      });
    case types.RESET_LOAD_ERROR:
      return state.withMutations(s => s.set('loadError', false));
    case types.RESET_UPDATE_ERROR:
      return state.withMutations(s => s.set('updateError', false));
    case types.CLOSE:
      return state.withMutations(s => s.set('open', false));
    default:
      return state;
  }
}

export const actions = {
  loadRequest: (entityId, entityType) => ({ type: types.LOAD_REQUEST, entityId, entityType }),
  loadSuccess: (entityId, entityType, entity) => ({ type: types.LOAD_SUCCESS, entityId, entityType, entity }),
  loadFailure: error => ({ type: types.LOAD_FAILURE, error }),
  updateRequest: (entityId, entityType, entity) => ({ type: types.UPDATE_REQUEST, entityId, entityType, entity }),
  updateSuccess: (entityId, entityType, entity) => ({ type: types.UPDATE_SUCCESS, entityId, entityType, entity }),
  updateFailure: error => ({ type: types.UPDATE_FAILURE, error }),
  resetLoadError: () => ({ type: types.RESET_LOAD_ERROR }),
  resetUpdateError: () => ({ type: types.RESET_UPDATE_ERROR }),
  close: () => ({ type: types.CLOSE }),
};

export const getRoot = state => state.getIn(['checkup', 'edit']);
export const isOpen = state => getRoot(state).get('open');
export const isLoadFetching = state => getRoot(state).get('loadIsFetching');
export const isUpdateFetching = state => getRoot(state).get('updateIsFetching');
export const getLoadError = state => getRoot(state).get('loadError');
export const getUpdateError = state => getRoot(state).get('updateError');
export const getEntityId = state => getRoot(state).get('entityId');
export const getEntityType = state => getRoot(state).get('entityType');
export const getEntity = state => getRoot(state).get('entity');
