import { withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { WORKFLOW_XPO } from 'components/checkup/utils';
import MasonryLayout from 'components/common/MasonryLayout';
import toJS from 'components/common/toJS';
import ChecklistStatsWidget from 'components/dashboard/ChecklistStatsWidget';
import TotalMovementsWidget from 'components/dashboard/TotalMovementsWidget';
import React from 'react';
import { connect } from 'react-redux';
import { getStats, getStatsIsFetching } from 'reducers/dashboard/checkup';

const styles = () => ({});

export const chartAspectRatio = 1.78;

function DashboardWidgets({ workflow, width, stats, isFetching }) {
  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = workflow === WORKFLOW_XPO ? 3 : 2;
  }

  const widgets = [];
  widgets.push(<TotalMovementsWidget key="total" value={stats} isFetching={isFetching} />);
  if (workflow === WORKFLOW_XPO) {
    widgets.push(
      <ChecklistStatsWidget
        key="trucks"
        valueKey="trucks"
        value={stats}
        isFetching={isFetching}
        title="Tractor checklists"
      />
    );
  }
  widgets.push(
    <ChecklistStatsWidget
      key="trailers"
      valueKey="trailers"
      value={stats}
      isFetching={isFetching}
      title="Chassis checklists"
    />
  );

  return (
    <MasonryLayout columns={columns} gap={32}>
      {widgets}
    </MasonryLayout>
  );
}

function mapStateToProps(state) {
  return {
    stats: getStats(state),
    isFetching: getStatsIsFetching(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(withWidth()(toJS(DashboardWidgets))));
