import { Checkbox, Chip, ListItemText, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import toJS from 'components/common/toJS';
import { get, sortBy } from 'lodash';
import React from 'react';

const styles = theme => ({
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  disabledChip: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
});

function YardsPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  color,
  onChange,
  value = [],
  availableYards,
  readOnlyYards,
  ...rest
}) {
  function handleChange(event) {
    const val = get(event, 'target.value', []);
    onChange(val.includes('') ? [] : val);
  }

  return (
    <TextField
      onChange={handleChange}
      className={className}
      select
      fullWidth
      label="Yards"
      margin="none"
      value={value}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        multiple: true,
        renderValue: selected => {
          if (!selected.length) {
            return '';
          }
          return (
            <div className={classes.chips}>
              {selected.map(yardId => {
                const readOnly = readOnlyYards.indexOf(yardId) > -1;
                const name = get(availableYards, `${yardId}.name`);
                return (
                  <Chip
                    disabled={readOnly}
                    key={name}
                    label={name}
                    className={classNames(classes.chip, { [classes.disabledChip]: readOnly })}
                    onDelete={readOnly ? undefined : () => onChange([...selected].filter(id => id !== yardId))}
                  />
                );
              })}
            </div>
          );
        },
        ...SelectProps,
      }}
    >
      {sortBy(Object.values(availableYards), 'name').map(({ id, name }) => (
        <MenuItem disabled={readOnlyYards.indexOf(id) > -1} key={id} value={id}>
          <Checkbox color="primary" checked={value.indexOf(id) > -1} />
          <ListItemText
            primary={
              <Typography variant="inherit" noWrap>
                {name}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

export default withStyles(styles)(toJS(YardsPicker));
