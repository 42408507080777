import { withStyles } from '@material-ui/core';
import CheckupList from 'components/checkup/CheckupList';
import { TYPE_CHECK_IN } from 'components/checkup/utils';
import React from 'react';

const styles = () => ({});

function CheckInList() {
  return <CheckupList entityType={TYPE_CHECK_IN} />;
}

export default withStyles(styles)(CheckInList);
