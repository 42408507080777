import { fromJS } from 'immutable';

export const types = {
  CREATE_REQUEST: 'CHECKUP_CREATE/CREATE_REQUEST',
  CREATE_SUCCESS: 'CHECKUP_CREATE/CREATE_SUCCESS',
  CREATE_FAILURE: 'CHECKUP_CREATE/CREATE_FAILURE',
  WARN_ALREADY_INSIDE: 'CHECKUP_CREATE/WARN_ALREADY_INSIDE',
  RESET_ERROR: 'CHECKUP_CREATE/RESET_ERROR',
  RESET: 'CHECKUP_CREATE/RESET',
};

export const initialState = fromJS({
  isFetching: false,
  error: false,
  alreadyInside: null,
});

export default function create(state = initialState, action) {
  const { type, trailer, container } = action;
  switch (type) {
    case types.CREATE_REQUEST:
      return state.withMutations(s => {
        s.set('isFetching', true);
        s.set('error', false);
        s.set('alreadyInside', null);
      });
    case types.CREATE_SUCCESS:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('error', false);
        s.set('alreadyInside', null);
      });
    case types.CREATE_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('error', true);
        s.set('alreadyInside', null);
      });
    case types.WARN_ALREADY_INSIDE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('alreadyInside', fromJS({ trailer, container }));
      });
    case types.RESET_ERROR:
      return state.withMutations(s => {
        s.set('error', false);
        s.set('alreadyInside', null);
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  createRequest: (entityType, entity, force = false) => ({ type: types.CREATE_REQUEST, entityType, entity, force }),
  createSuccess: (entityType, entity) => ({ type: types.CREATE_SUCCESS, entityType, entity }),
  createFailure: error => ({ type: types.CREATE_FAILURE, error }),
  warnAlreadyInside: (trailer, container) => ({ type: types.WARN_ALREADY_INSIDE, trailer, container }),
  resetError: () => ({ type: types.RESET_ERROR }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = state => state.getIn(['checkup', 'create']);
export const getIsFetching = state => getRoot(state).get('isFetching');
export const getError = state => getRoot(state).get('error');
export const getAlreadyInside = state => getRoot(state).get('alreadyInside');
