import { Grid, withStyles } from '@material-ui/core';
import { momentUserTz } from 'components/common/momentUtils';
import { dateFormat, minDate } from 'components/common/useDateRange';
import YardPicker from 'components/pickers/YardPicker';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';

const styles = theme => ({
  datePickers: {
    '& > * + *': {
      marginLeft: theme.spacing(8),
    },
  },
});

function CheckupParamsPicker({
  classes,
  hideDatePickers = false,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  yardId,
  onYardIdChange,
}) {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12} sm={12} md={6} className={classes.datePickers} container wrap="nowrap">
        {!hideDatePickers && (
          <>
            <DatePicker
              autoOk
              showTodayButton={momentUserTz(endDate, dateFormat).isSame(momentUserTz(), 'day')}
              label="From"
              format={dateFormat}
              value={momentUserTz(startDate, dateFormat)}
              onChange={onStartDateChange}
              minDate={momentUserTz(minDate, dateFormat)}
              maxDate={momentUserTz(endDate, dateFormat)}
              disableFuture
            />
            <DatePicker
              autoOk
              showTodayButton
              label="To"
              format={dateFormat}
              value={momentUserTz(endDate, dateFormat)}
              onChange={onEndDateChange}
              minDate={momentUserTz(minDate, dateFormat)}
              disableFuture
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={3} xl={2} container>
        <YardPicker fullWidth value={yardId} onChange={onYardIdChange} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CheckupParamsPicker);
