import React from 'react';
import { Helmet } from 'react-helmet-async';

function SetTitle({ title }) {
  return (
    <Helmet>
      <title>{title} | omniX Yard</title>
    </Helmet>
  );
}

export default React.memo(SetTitle);
