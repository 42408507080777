import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import UserEdit from 'components/user/UserEdit';
import React from 'react';
import { connect } from 'react-redux';
import {
  actions,
  getEntity,
  getLoadError,
  getUpdateError,
  isLoadFetching,
  isNew,
  isOpen,
  isUpdateFetching,
} from 'reducers/user/edit';

const styles = theme => ({
  paperRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    flexGrow: 1,
  },
});

function UserEditor({
  classes,
  open,
  isNew,
  fullScreen,
  loadIsFetching,
  loadError,
  resetLoadError,
  updateIsFetching,
  updateError,
  resetUpdateError,
  entity,
  onSubmit,
  onClose,
}) {
  const operation = isNew ? 'Create' : 'Edit';
  return (
    <>
      {open && <SetTitle title={`${operation} user`} />}
      <Dialog
        PaperProps={{
          id: 'user-editor-dialog-paper',
          className: classes.paperRoot,
        }}
        open={open}
        fullScreen={fullScreen}
        aria-labelledby="user-editor-dialog-title"
      >
        <DialogTitle id="user-editor-dialog-title" className={classes.title} disableTypography>
          <Typography variant="h6">{operation} user</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loadIsFetching && (
          <DialogContent className={classes.content}>
            <BrandedLoadingIndicator />
          </DialogContent>
        )}
        {!loadIsFetching && entity && (
          <UserEdit
            onClose={onClose}
            isNew={isNew}
            initialEntity={entity}
            isFetching={updateIsFetching}
            error={updateError}
            onSubmit={onSubmit}
            resetError={resetUpdateError}
          />
        )}
      </Dialog>
      <Dialog
        open={loadError}
        onClose={resetLoadError}
        aria-labelledby="user-editor-load-dialog-title"
        aria-describedby="user-editor-load-dialog-description"
      >
        <DialogTitle id="user-editor-load-dialog-title">Edit failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="user-editor-load-dialog-description">
            Failed to load user for edit, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetLoadError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isOpen(state),
    isNew: isNew(state),
    loadError: getLoadError(state),
    loadIsFetching: isLoadFetching(state),
    updateIsFetching: isUpdateFetching(state),
    updateError: getUpdateError(state),
    entity: getEntity(state),
  };
}

export default connect(
  mapStateToProps,
  {
    onClose: actions.close,
    resetLoadError: actions.resetLoadError,
    resetUpdateError: actions.resetUpdateError,
    onSubmit: actions.updateRequest,
  }
)(withMobileDialog()(withStyles(styles)(UserEditor)));
