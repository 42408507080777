import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Typography } from '@material-ui/core';
import { PRIMARY_DARK_COLOR } from 'components/style/colors';

const styles = (theme) => ({
  footer: {
    backgroundColor: PRIMARY_DARK_COLOR,
    color: theme.palette.primary.contrastText,
    minHeight: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function Footer({ classes }) {
  return (
    <footer className={classes.footer}>
      <Typography variant="subtitle1" align="center" color="inherit" component="p">
        Powered by omniX labs
      </Typography>
    </footer>
  );
}

export default withStyles(styles)(React.memo(Footer));
