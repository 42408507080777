import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TiresIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/MoreVert';
import NotesIcon from '@material-ui/icons/Notes';
import MultifieldChecklistItemEditor from 'components/add/MultifieldChecklistItemEditor';
import {
  brakesAndAxlesChecklist,
  getTitleForType,
  tiresChecklist,
  WORKFLOW_ROADRUNNER,
} from 'components/checkup/utils';
import { WARN_COLOR } from 'components/style/colors';
import { upperFirst } from 'lodash';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { getUserRoleName } from 'reducers/auth';
import { actions as editActions } from 'reducers/checkup/edit';
import { actions as deleteActions } from 'reducers/entity/delete';

const styles = theme => ({
  notesBadge: {
    backgroundColor: WARN_COLOR,
  },
  menuPaper: {
    minWidth: 200,
  },
  notesDialogPaper: {
    minWidth: 300,
  },
  notesDialogContentText: {
    overflow: 'auto',
    whiteSpace: 'pre-line',
  },
  tiresDialogContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
});

function CheckupActionMenu({ classes, workflow, entity, onEdit, onDelete, userRole, ...rest }) {
  const { id, notes } = entity;
  const [anchorEl, setAnchorEl] = useState(null);
  const [notesOpen, setNotesOpen] = useState(false);
  const [tiresOpen, setTiresOpen] = useState(false);
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleEdit = useCallback(() => {
    setAnchorEl(null);
    onEdit(entity.id, entity.name);
  }, [entity, onEdit]);
  const handleDelete = useCallback(() => {
    setAnchorEl(null);
    onDelete(entity.name, entity);
  }, [entity, onDelete]);
  const handleOpenNotes = useCallback(() => {
    setAnchorEl(null);
    setNotesOpen(true);
  }, []);
  const handleOpenTires = useCallback(() => {
    setAnchorEl(null);
    setTiresOpen(true);
  }, []);
  const handleCloseNotes = useCallback(() => setNotesOpen(false), []);
  const handleCloseTires = useCallback(() => setTiresOpen(false), []);
  const open = Boolean(anchorEl);

  return (
    <div {...rest}>
      <IconButton
        disabled={userRole === 'supervisor' && !notes}
        aria-label="Actions"
        aria-owns={open ? `${id}-actions-menu` : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {notes ? (
          <Badge classes={{ badge: classes.notesBadge }} variant="dot">
            <MenuIcon />
          </Badge>
        ) : (
          <MenuIcon />
        )}
      </IconButton>
      <Menu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id={`${id}-actions-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {userRole !== 'supervisor' && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            Edit
          </MenuItem>
        )}
        {notes && (
          <MenuItem onClick={handleOpenNotes}>
            <ListItemIcon>
              <Badge classes={{ badge: classes.notesBadge }} variant="dot">
                <NotesIcon />
              </Badge>
            </ListItemIcon>
            View notes
          </MenuItem>
        )}
        {workflow === WORKFLOW_ROADRUNNER && entity.trailerChecklist.tires && (
          <MenuItem onClick={handleOpenTires}>
            <ListItemIcon>
              <TiresIcon />
            </ListItemIcon>
            View brakes & axles, tires
          </MenuItem>
        )}
        {userRole !== 'supervisor' && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>

      <Dialog
        classes={{
          paper: classes.notesDialogPaper,
        }}
        open={notesOpen}
        onClose={handleCloseNotes}
        aria-labelledby={`${id}-notes-dialog-title`}
        aria-describedby={`${id}-notes-dialog-description`}
      >
        <DialogTitle id={`${id}-notes-dialog-title`}>{upperFirst(getTitleForType(entity.name))} notes</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.notesDialogContentText} id={`${id}-notes-dialog-description`}>
            {notes}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotes} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          paper: classes.notesDialogPaper,
        }}
        open={tiresOpen}
        onClose={handleCloseTires}
        aria-labelledby={`${id}-tires-dialog-title`}
        aria-describedby={`${id}-tires-dialog-description`}
      >
        <DialogTitle id={`${id}-tires-dialog-title`}>Brakes & axles, tires</DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            className={classes.tiresDialogContent}
            id={`${id}-notes-dialog-description`}
          >
            <MultifieldChecklistItemEditor
              readOnly
              columns={2}
              rootKey="brakesAndAxles"
              title="Brakes & axles"
              checklistItems={brakesAndAxlesChecklist}
              value={entity.trailerChecklist.brakesAndAxles}
            />
            <MultifieldChecklistItemEditor
              readOnly
              columns={2}
              rootKey="tires"
              title="Tires"
              checklistItems={tiresChecklist}
              value={entity.trailerChecklist.tires}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTires} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userRole: getUserRoleName(state),
  };
}

export default connect(mapStateToProps, { onEdit: editActions.loadRequest, onDelete: deleteActions.openDialog })(
  withStyles(styles)(CheckupActionMenu)
);
