import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  progress: {
    marginRight: theme.spacing(4),
  },
}));

function InlineLoadingIndicator({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      <CircularProgress variant="indeterminate" size={24} color="inherit" className={classes.progress} />
      <Typography variant="subtitle2" component="span" color="inherit">
        Loading...
      </Typography>
    </div>
  );
}

export default InlineLoadingIndicator;
