import { makeStyles, Paper } from '@material-ui/core';
import CheckupParamsPicker from 'components/checkup/CheckupParamsPicker';
import CheckupSearchHelperText from 'components/checkup/CheckupSearchHelperText';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import useQueryParam from 'components/common/useQueryParam';
import ContainersInsideList from 'components/inside/ContainersInsideList';
import React, { useEffect, useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultYardId } from 'reducers/auth';
import { actions } from 'reducers/inside/containers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

function ContainersInside({ initialYardId, searchRequest, onUnmount, location, history }) {
  const classes = useStyles();
  useWillUnmount(onUnmount);
  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const [yardId, setYardId] = useState(initialYardId);
  const [searchForceRefresh, setSearchForceRefresh] = useState(false);
  useEffect(() => {
    searchRequest(yardId, query);
  }, [yardId, query, searchRequest, searchForceRefresh]);
  useEffect(() => {
    localStorage.setItem('lastUsedYardId', yardId);
  }, [yardId]);

  return (
    <div className={classes.root}>
      <SetTitle title="Containers in yard" />
      <CheckupParamsPicker hideDatePickers yardId={yardId} onYardIdChange={(event) => setYardId(event.target.value)} />
      <div className={classes.searchBoxContainer}>
        <SearchBox
          helperText={<CheckupSearchHelperText />}
          location={location}
          history={history}
          placeholder="Search by tractor, container, chassis, etc."
          onSearch={() => setSearchForceRefresh((previous) => !previous)}
        />
      </div>
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <ContainersInsideList yardId={yardId} query={query} />
        </div>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    initialYardId: getDefaultYardId(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { searchRequest: actions.searchRequest, onUnmount: actions.reset })(ContainersInside)
);
