import { withStyles } from '@material-ui/core/styles';
import AddMovementIcon from '@material-ui/icons/Add';
import DashboardIcon from '@material-ui/icons/BarChart';
import UsersIcon from '@material-ui/icons/Group';
import ContainersInsideIcon from '@material-ui/icons/OpenInBrowser';
import SSLineIcon from '@material-ui/icons/DirectionsBoat';
import logo from 'components/media/logo_black.svg';
import MenuAppBar from 'components/menu/MenuAppBar';
import ImpersonationStatus from 'components/user/ImpersonationStatus';
import UserInfoNavigationMenu from 'components/user/UserInfoNavigationMenu';
import React, { useEffect, useState } from 'react';
import { FaSignInAlt as CheckInIcon, FaSignOutAlt as CheckOutIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getUserRoleName } from 'reducers/auth';

import { Divider, Drawer, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export const DRAWER_WIDTH = 270;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  logo: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
    height: 100,
    width: '100%',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
    overflowX: 'hidden',
  },
  icon: {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 2,
  },
});

function NavigationDrawer({ classes, location, userRole }) {
  const { pathname } = location;
  const usersAuthorized = userRole !== 'guard' && userRole !== 'supervisor';
  const ssLinesAuthorized = userRole !== 'guard' && userRole !== 'supervisor';

  return (
    <Grid className={classes.grow} container direction="column" wrap="nowrap">
      <ImpersonationStatus />
      <Link to="/">
        <img src={logo} className={classes.logo} alt="omniX logo" />
      </Link>

      <Divider />
      <List>
        <ListItem button selected={pathname === '/add'} component={Link} to="/add">
          <ListItemIcon>
            <AddMovementIcon />
          </ListItemIcon>
          <ListItemText primary="Add movement" />
        </ListItem>
        {userRole !== 'guard' && (
          <>
            <ListItem button selected={pathname === '/dashboard'} component={Link} to="/dashboard">
              <ListItemIcon>
                <DashboardIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button selected={pathname === '/checkins'} component={Link} to="/checkins">
              <ListItemIcon>
                <CheckInIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Check ins" />
            </ListItem>
            <ListItem button selected={pathname === '/checkouts'} component={Link} to="/checkouts">
              <ListItemIcon>
                <CheckOutIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Check outs" />
            </ListItem>
            <ListItem button selected={pathname === '/containers-inside'} component={Link} to="/containers-inside">
              <ListItemIcon>
                <ContainersInsideIcon />
              </ListItemIcon>
              <ListItemText primary="Containers in yard" />
            </ListItem>
            <ListItem button selected={pathname === '/chassis-inside'} component={Link} to="/chassis-inside">
              <ListItemIcon>
                <ContainersInsideIcon />
              </ListItemIcon>
              <ListItemText primary="Chassis in yard" />
            </ListItem>
          </>
        )}
        {ssLinesAuthorized && (
          <ListItem button selected={pathname === '/sslines'} component={Link} to="/sslines">
            <ListItemIcon>
              <SSLineIcon />
            </ListItemIcon>
            <ListItemText primary="SS lines" />
          </ListItem>
        )}
        {usersAuthorized && (
          <>
            <Divider />
            <ListItem button selected={pathname === '/users'} component={Link} to="/users">
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </>
        )}
      </List>

      <Grid className={classes.grow} direction="column" container justify="flex-end">
        <Divider />
        <UserInfoNavigationMenu />
      </Grid>
    </Grid>
  );
}

function PrimaryNavigation({ classes, theme, location, userRole }) {
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => setMobileOpen(false), [location]);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
      <MenuAppBar onMenuClick={handleDrawerToggle} />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <NavigationDrawer classes={classes} location={location} userRole={userRole} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <NavigationDrawer classes={classes} location={location} userRole={userRole} />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userRole: getUserRoleName(state),
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(PrimaryNavigation)));
