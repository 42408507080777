import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

const useStyles = makeStyles(() => ({
  secondaryWhite: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const rows = [
  { query: 'John OR Dave', explanation: 'Users whose name is "John" or "Dave"' },
  { query: 'name: Steve*', explanation: 'Users whose name starts with "Steve"' },
  {
    query: 'email: *dan*',
    explanation: 'Users whose email contains "dan", this could be "dan@example.com" as well as "daniel@example.com"',
  },
  { query: 'email: *gmail.com', explanation: 'Users whose email ends with "gmail.com"' },
  { query: 'email: *bob* OR name: *bob*', explanation: 'Users whose name or email contains "bob"' },
];

function UserSearchHelperText() {
  const classes = useStyles();

  return (
    <>
      You can use the search query to filter users in many ways. Here are some examples:
      <List dense>
        {rows.map(({ query, explanation }) => (
          <ListItem alignItems="flex-start" key={query}>
            <ListItemAvatar>
              <SearchIcon />
            </ListItemAvatar>
            <ListItemText primary={query} secondary={<span className={classes.secondaryWhite}>{explanation}</span>}>
              {query}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default UserSearchHelperText;
