import { Badge, Chip, IconButton, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ChecklistSuccessfulIcon from '@material-ui/icons/DoneAll';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChecklistIcon from '@material-ui/icons/ListAlt';
import { getFailedCheckListItems } from 'components/checkup/utils';
import React, { useState } from 'react';

const styles = (theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
  },
  chipsContainer: {
    marginTop: theme.spacing(1),
    display: 'inline-block',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  badgeContainer: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    backgroundColor: red[100],
  },
  chipLabel: {
    userSelect: 'unset',
  },
});

function SerialWithChecklist({ classes, value, checklist, checklistDefinition }) {
  const [expanded, setExpanded] = useState(false);

  const failedItems =
    (checklist && checklistDefinition && getFailedCheckListItems(checklist, checklistDefinition)) || [];

  return (
    <>
      <div className={classes.nameContainer}>
        <div className={classes.badgeContainer}>
          {failedItems.length ? (
            <IconButton onClick={() => setExpanded((state) => !state)}>
              <Badge badgeContent={failedItems.length} color="error">
                {expanded ? <ExpandLessIcon /> : <ChecklistIcon />}{' '}
              </Badge>
            </IconButton>
          ) : (
            <ChecklistSuccessfulIcon />
          )}
        </div>
        {value}
      </div>
      {expanded && (
        <div className={classes.chipsContainer}>
          {failedItems.map((item) => (
            <div key={item}>
              <Chip classes={{ root: classes.chip, label: classes.chipLabel }} label={item} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default withStyles(styles)(SerialWithChecklist);
