import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MobileStepper,
  Paper,
  TextField,
  Typography,
  withStyles,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import WarningIcon from '@material-ui/icons/Warning';
import ChecklistEditor from 'components/add/ChecklistEditor';
import CheckupErrorsDialog from 'components/add/CheckupErrorsDialog';
import MultifieldChecklistItemEditor from 'components/add/MultifieldChecklistItemEditor';
import SSLineName from 'components/add/SSLineName';
import TextRecognitionField from 'components/add/TextRecognitionField';
import {
  brakesAndAxlesChecklist,
  createSuccessfulChecklist,
  getTitleForType,
  LOAD_TYPE_EMPTY,
  LOAD_TYPE_LOADED,
  resolveSSLineName,
  tiresChecklist,
  trailerChecklistDefinition,
  truckChecklistDefinition,
  TYPE_CHECK_IN,
  TYPE_CHECK_OUT,
  WORKFLOW_ROADRUNNER,
  WORKFLOW_XPO,
} from 'components/checkup/utils';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import { momentUserTz } from 'components/common/momentUtils';
import StaticTextField from 'components/common/StaticTextField';
import toJS from 'components/common/toJS';
import useYardWorkflow from 'components/common/useYardWorkflow';
import YardPicker from 'components/pickers/YardPicker';
import { WARN_COLOR } from 'components/style/colors';
import Hammer from 'hammerjs';
import { compact, filter, findIndex, get, transform, upperFirst } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getDefaultYardId, getYards } from 'reducers/auth';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  paper: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  content: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 360,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  stepTitle: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    '&::after': {
      left: '10%',
      width: '80%',
      height: 6,
      bottom: -16,
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  stepper: {
    width: '100%',
  },
  containerControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  noContainerSwitch: {
    width: '100%',
  },
  actionButtons: {
    marginTop: theme.spacing(8),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '&::after': {
      left: '10%',
      width: '80%',
      height: 2,
      top: -theme.spacing(4),
      content: '""',
      position: 'absolute',
      backgroundColor: grey[900],
    },
  },
  primaryButton: {
    width: '50%',
  },
  warnItemIcon: {
    color: WARN_COLOR,
  },
  warnItemText: {
    padding: 0,
  },
});

const containerValidation = {
  shipping: {
    regex: /^[A-Z]{4}[0-9]{7}$/,
    message: '4 letters and 7 numbers',
  },
  railway: {
    regex: /^[A-Z]{4}[0-9]{6}$/,
    message: '4 letters and 6 numbers',
  },
};
const trailerRegex = /^[A-Z]{4}[0-9]{6}$/;

function checkListItemsErrors(requireChecklists, entityType, workflow, checklist, checklistDefinition) {
  if (!requireChecklists) {
    return [];
  }
  const errors = filter(checklistDefinition, (item) => {
    const value = checklist[item.key];
    return value !== false && value !== true;
  }).map((item) => item.name);
  if (entityType === TYPE_CHECK_IN && workflow === WORKFLOW_ROADRUNNER) {
    const { brakesAndAxles, tires } = checklist;
    brakesAndAxlesChecklist.some(({ key, denominator }) => {
      const value = brakesAndAxles[key];
      return value === null || value === undefined || !value.trim() || value < 0 || value > denominator;
    }) && errors.push('Brakes & axles');
    tiresChecklist.some(({ key, denominator }) => {
      const value = tires[key];
      return value === null || value === undefined || !value.trim() || value < 0 || value > denominator;
    }) && errors.push('Tires');
  }
  return errors;
}

function truckChecklistInitialValue(entityType, initialEntity) {
  if (entityType === TYPE_CHECK_IN) {
    return createSuccessfulChecklist(truckChecklistDefinition);
  }
  return { ...get(initialEntity, 'truckChecklist', {}) };
}

function trailerChecklistInitialValue(entityType, workflow, initialEntity) {
  const brakesAndAxles = transform(brakesAndAxlesChecklist, (result, entry) => (result[entry.key] = ''), {});
  const tires = transform(tiresChecklist, (result, entry) => (result[entry.key] = ''), {});
  const initialValue = {
    brakesAndAxles,
    tires,
  };
  if (entityType === TYPE_CHECK_IN && workflow === WORKFLOW_XPO) {
    return { ...createSuccessfulChecklist(trailerChecklistDefinition), brakesAndAxles, tires };
  }
  return { ...get(initialEntity, 'trailerChecklist', initialValue) };
}

function calculateRequireChecklistAndMaxSteps(entityType, yardId, workflow, availableYards) {
  let requireChecklists;
  let maxSteps;
  if (workflow === WORKFLOW_XPO) {
    requireChecklists = entityType === TYPE_CHECK_OUT && get(availableYards, `${yardId}.requireChecklists`, true);
    maxSteps = requireChecklists ? 3 : 1;
  } else {
    requireChecklists = entityType === TYPE_CHECK_IN && get(availableYards, `${yardId}.requireChecklists`, true);
    maxSteps = requireChecklists ? 2 : 1;
  }
  return [requireChecklists, maxSteps];
}

function getStepTitles(workflow) {
  return workflow === WORKFLOW_XPO
    ? ['Assets information', 'Tractor checklist', 'Chassis checklist']
    : ['Assets information', 'Chassis checklist'];
}

function CheckupWizard({
  classes,
  entityType,
  initialEntity,
  initialYardId,
  availableYards,
  isFetching,
  alreadyInside,
  error,
  resetError,
  onSubmit,
  onStepChange,
}) {
  const [yardId, setYardId] = useState(initialYardId);
  const workflow = useYardWorkflow(yardId);
  const stepTitles = getStepTitles(workflow);
  const [requireChecklists, maxSteps] = calculateRequireChecklistAndMaxSteps(
    entityType,
    yardId,
    workflow,
    availableYards
  );
  const containerType = get(availableYards, `${yardId}.containerType`, 'shipping');
  const { regex: containerRegex, message: containerRegexMessage } = containerValidation[containerType];

  const isNew = !initialEntity;
  const titleLc = getTitleForType(entityType);
  const title = upperFirst(titleLc);

  const mounted = useRef();
  const contentRef = useRef();
  const [step, setStep] = useState(0);
  const [truck, setTruck] = useState(get(initialEntity, 'truckSerial') || '');
  const [trailer, setTrailer] = useState(get(initialEntity, 'trailerSerial') || '');
  const [trailerSize, setTrailerSize] = useState(get(initialEntity, 'trailerSize') || '');
  const [noContainer, setNoContainer] = useState(
    get(initialEntity, 'id') ? !get(initialEntity, 'containerSerial') : false
  );
  const [container, setContainer] = useState(get(initialEntity, 'containerSerial') || '');
  const [size, setSize] = useState(get(initialEntity, 'containerSize') || '');
  const [loadType, setLoadType] = useState(get(initialEntity, 'loadType') || '');
  const [sealNumber, setSealNumber] = useState(get(initialEntity, 'containerSeal') || '');
  const [truckingCompany, setTruckingCompany] = useState(get(initialEntity, 'truckingCompany') || '');
  const [driverName, setDriverName] = useState(get(initialEntity, 'driverName') || '');
  const [truckChecklist, setTruckChecklist] = useState(truckChecklistInitialValue(entityType, initialEntity));
  const [trailerChecklist, setTrailerChecklist] = useState(
    trailerChecklistInitialValue(entityType, workflow, initialEntity)
  );
  const [notes, setNotes] = useState(get(initialEntity, 'notes') || '');
  const [showErrorsDialog, setShowErrorsDialog] = useState(false);
  const [wasSubmit, setWasSubmit] = useState(false);
  const [showFormatWarn, setShowFormatWarn] = useState(false);
  const resetFormatWarn = useCallback(() => setShowFormatWarn(false), []);

  const ssLine = container.length >= 4 && resolveSSLineName(container);

  useLayoutEffect(() => {
    const manager = new Hammer.Manager(contentRef.current);
    const swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL });

    manager.add(swipe);

    manager.on('swipe', (event) => {
      const direction = event.offsetDirection;
      if (direction === 2) {
        setStep((s) => (s === maxSteps - 1 ? s : s + 1));
      }
      if (direction === 4) {
        setStep((s) => (s === 0 ? s : s - 1));
      }
    });

    return () => manager.destroy();
  }, [maxSteps]);
  useLayoutEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      onStepChange && onStepChange(step);
    }
  }, [step, onStepChange]);
  useEffect(() => {
    localStorage.setItem('lastUsedYardId', yardId);
  }, [yardId]);

  const yardError = !yardId ? 'Select yard' : null;
  const truckError = !truck.trim() ? 'Tractor is required' : null;
  const trailerError = !trailer.trim() ? 'Chassis is required' : null;
  const containerError = !noContainer && !container.trim() ? 'Container is required' : null;
  const sizeError = !noContainer && size === '' ? 'Select container size' : null;
  const loadTypeError = !noContainer && loadType === '' ? 'Select load type' : null;
  const sealNumberError =
    !noContainer && loadType === LOAD_TYPE_LOADED && !sealNumber.trim() ? 'Seal number is required' : null;
  const truckingCompanyError =
    workflow === WORKFLOW_ROADRUNNER && !truckingCompany.trim() ? 'Trucking company is required' : null;
  const driverNameError = workflow === WORKFLOW_ROADRUNNER && !driverName.trim() ? 'Driver name is required' : null;

  const validationErrors = {
    [stepTitles[0]]: compact([
      yardError,
      truckError,
      trailerError,
      containerError,
      sizeError,
      loadTypeError,
      sealNumberError,
      truckingCompanyError,
      driverNameError,
    ]),
    [stepTitles[1]]:
      workflow === WORKFLOW_XPO
        ? checkListItemsErrors(requireChecklists, entityType, workflow, truckChecklist, truckChecklistDefinition)
        : checkListItemsErrors(requireChecklists, entityType, workflow, trailerChecklist, trailerChecklistDefinition),
    [stepTitles[2]]:
      workflow === WORKFLOW_XPO &&
      checkListItemsErrors(requireChecklists, entityType, workflow, trailerChecklist, trailerChecklistDefinition),
  };

  function handleSubmit() {
    setWasSubmit(true);
    const stepWithError = findIndex(Object.values(validationErrors), (value) => value.length);
    if (stepWithError !== -1) {
      setStep(stepWithError);
      setShowErrorsDialog(true);
    } else if (!isFetching) {
      if (containerRegex.test(container.trim()) && trailerRegex.test(trailer.trim())) {
        doSubmit();
      } else {
        setShowFormatWarn(true);
      }
    }
  }

  function doSubmit(force = false) {
    const entity = {
      ...initialEntity,
      truckSerial: truck,
      trailerSerial: trailer,
      trailerSize,
      truckChecklist: requireChecklists ? truckChecklist : createSuccessfulChecklist(truckChecklistDefinition),
      trailerChecklist: requireChecklists ? trailerChecklist : createSuccessfulChecklist(trailerChecklistDefinition),
      yardId,
      truckingCompany,
      driverName,
      notes,
      containerSerial: noContainer ? null : container,
      containerSize: noContainer ? null : size,
      loadType: noContainer ? null : loadType.toLowerCase(),
      containerSeal: !noContainer && loadType === LOAD_TYPE_LOADED ? sealNumber : null,
    };

    if (isNew) {
      onSubmit(entityType, entity, force);
    } else {
      onSubmit(initialEntity.id, entityType, entity);
    }
  }

  return (
    <div className={classes.root}>
      <CheckupErrorsDialog
        title={title}
        open={showErrorsDialog}
        errors={validationErrors}
        onClose={() => setShowErrorsDialog(false)}
      />
      <Dialog
        open={error}
        onClose={resetError}
        aria-labelledby="checkup-wizard-error-dialog-title"
        aria-describedby="checkup-wizard-error-dialog-description"
      >
        <DialogTitle id="checkup-wizard-error-dialog-title">Save failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="checkup-wizard-error-dialog-description">
            Failed to save {titleLc}, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showFormatWarn}
        onClose={resetFormatWarn}
        aria-labelledby="checkup-wizard-warn-dialog-title"
        aria-describedby="checkup-wizard-warn-dialog-description"
      >
        <DialogTitle id="checkup-wizard-warn-dialog-title">Possibly incorrect data</DialogTitle>
        <DialogContent>
          <DialogContentText component="div" id="checkup-wizard-warn-dialog-description">
            <List disablePadding>
              {!trailerRegex.test(trailer.trim()) && (
                <ListItem alignItems="flex-start">
                  <ListItemIcon className={classes.warnItemIcon}>
                    <WarningIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.warnItemText}
                    inset={false}
                    primary={
                      <>
                        Chassis <strong>{trailer}</strong> seems to be incorrect, normally it's{' '}
                        <strong>4 letters and 6 numbers</strong>
                      </>
                    }
                  />
                </ListItem>
              )}
              {!noContainer && !containerRegex.test(container.trim()) && (
                <ListItem alignItems="flex-start">
                  <ListItemIcon className={classes.warnItemIcon}>
                    <WarningIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.warnItemText}
                    inset={false}
                    primary={
                      <>
                        Container <strong>{container}</strong> seems to be incorrect, normally it's{' '}
                        <strong>{containerRegexMessage}</strong>
                      </>
                    }
                  />
                </ListItem>
              )}
            </List>
            Are you sure you want to save this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setStep(0);
              resetFormatWarn();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              resetFormatWarn();
              doSubmit();
            }}
            variant="contained"
            color="secondary"
          >
            Save as is
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!alreadyInside}
        transitionDuration={0}
        onClose={resetError}
        aria-labelledby="checkup-wizard-warn-dialog-title"
        aria-describedby="checkup-wizard-warn-dialog-description"
      >
        <DialogTitle id="checkup-wizard-warn-dialog-title">Possibly incorrect data</DialogTitle>
        <DialogContent>
          <DialogContentText component="div" id="checkup-wizard-warn-dialog-description">
            <List disablePadding>
              {alreadyInside && alreadyInside.trailer && (
                <ListItem alignItems="flex-start">
                  <ListItemIcon className={classes.warnItemIcon}>
                    <WarningIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.warnItemText}
                    inset={false}
                    primary={
                      <>
                        Chassis <strong>{trailer}</strong> is already inside the yard, entry time{' '}
                        <strong>{momentUserTz(alreadyInside.trailer.createdAt).format('MM/DD/YYYY HH:mm:ss')}</strong>.
                      </>
                    }
                  />
                </ListItem>
              )}
              {alreadyInside && alreadyInside.container && (
                <ListItem alignItems="flex-start">
                  <ListItemIcon className={classes.warnItemIcon}>
                    <WarningIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.warnItemText}
                    inset={false}
                    primary={
                      <>
                        Container <strong>{container}</strong> is already inside the yard, entry time{' '}
                        <strong>{momentUserTz(alreadyInside.container.createdAt).format('MM/DD/YYYY HH:mm:ss')}</strong>
                        .
                      </>
                    }
                  />
                </ListItem>
              )}
            </List>
            Are you sure you want to save this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setStep(0);
              resetError();
            }}
            variant="text"
          >
            Cancel
          </Button>
          <Button onClick={() => doSubmit(true)} variant="contained" color="secondary">
            Save as is
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={classes.paper}>
        <div className={classes.content} ref={contentRef}>
          <Typography className={classes.stepTitle} variant="h6" align="center">
            {stepTitles[step]}
          </Typography>
          {step === 0 && (
            <>
              {isNew ? (
                <YardPicker
                  error={wasSubmit && yardError != null}
                  helperText={wasSubmit && yardError}
                  fullWidth
                  value={yardId}
                  onChange={(event) => setYardId(event.target.value)}
                />
              ) : (
                <>
                  <StaticTextField value={initialEntity.yard.name} label="Yard" />
                  <StaticTextField
                    value={momentUserTz(initialEntity.createdAt).format('MM/DD/YYYY HH:mm:ss')}
                    label="Time"
                  />
                  <StaticTextField value={initialEntity.creator.name} label="Reported by" />
                </>
              )}
              <TextRecognitionField
                error={wasSubmit && truckError != null}
                helperText={wasSubmit && truckError}
                value={truck}
                onChange={(value) => setTruck(value)}
                inputProps={{ required: true }}
                label="Tractor"
                autoComplete="off"
                name="truckSerial"
                margin="none"
                fullWidth
              />
              <TextRecognitionField
                error={wasSubmit && trailerError != null}
                helperText={wasSubmit && trailerError}
                value={trailer}
                onChange={(value) => setTrailer(value)}
                inputProps={{ required: true }}
                label="Chassis"
                autoComplete="off"
                name="trailerSerial"
                margin="none"
                fullWidth
              />
              <div className={classes.containerControls}>
                <FormControlLabel
                  className={classes.noContainerSwitch}
                  control={
                    <Switch
                      checked={noContainer}
                      onChange={(event) => setNoContainer(event.target.checked)}
                      value="noContainer"
                      color="secondary"
                    />
                  }
                  label="Container is not present"
                />
                {!noContainer && (
                  <TextRecognitionField
                    error={wasSubmit && containerError != null}
                    helperText={(wasSubmit && containerError) || (ssLine && <SSLineName value={ssLine} />)}
                    value={container}
                    onChange={(value) => setContainer(value)}
                    inputProps={{ required: true }}
                    label="Container"
                    autoComplete="off"
                    name="containerSerial"
                    margin="none"
                    fullWidth
                  />
                )}
              </div>
              {!noContainer && (
                <>
                  <TextField
                    error={wasSubmit && sizeError != null}
                    helperText={wasSubmit && sizeError}
                    value={size}
                    onChange={(event) => setSize(event.target.value)}
                    select
                    label="Container size"
                    fullWidth
                    margin="none"
                  >
                    <MenuItem value={53}>53 ft</MenuItem>
                    <MenuItem value={45}>45 ft</MenuItem>
                    <MenuItem value={40}>40 ft</MenuItem>
                    <MenuItem value={20}>20 ft</MenuItem>
                  </TextField>
                  <TextField
                    error={wasSubmit && loadTypeError != null}
                    helperText={wasSubmit && loadTypeError}
                    value={loadType}
                    onChange={(event) => setLoadType(event.target.value)}
                    select
                    label="Load type"
                    fullWidth
                    margin="none"
                  >
                    <MenuItem value={LOAD_TYPE_LOADED}>Loaded</MenuItem>
                    <MenuItem value={LOAD_TYPE_EMPTY}>Empty</MenuItem>
                  </TextField>
                  {loadType === LOAD_TYPE_LOADED && (
                    <TextField
                      error={wasSubmit && sealNumberError != null}
                      helperText={wasSubmit && sealNumberError}
                      value={sealNumber}
                      onChange={(event) => setSealNumber(event.target.value.toUpperCase())}
                      inputProps={{ required: true }}
                      label="Seal number"
                      autoComplete="off"
                      name="containerSeal"
                      margin="none"
                      fullWidth
                    />
                  )}
                </>
              )}
              {entityType === TYPE_CHECK_IN && (
                <TextField
                  value={trailerSize}
                  onChange={(event) => setTrailerSize(event.target.value)}
                  select
                  label="Chassis size"
                  fullWidth
                  margin="none"
                >
                  <MenuItem value={53}>53 ft</MenuItem>
                  <MenuItem value={45}>45 ft</MenuItem>
                  <MenuItem value={40}>40 ft</MenuItem>
                  <MenuItem value={20}>20 ft</MenuItem>
                </TextField>
              )}
              {workflow === WORKFLOW_ROADRUNNER && (
                <>
                  <TextField
                    error={wasSubmit && truckingCompanyError != null}
                    helperText={wasSubmit && truckingCompanyError}
                    value={truckingCompany}
                    onChange={(event) => setTruckingCompany(event.target.value)}
                    inputProps={{ required: true }}
                    label="Trucking company"
                    autoComplete="off"
                    name="truckingCompany"
                    margin="none"
                    fullWidth
                  />
                  <TextField
                    error={wasSubmit && driverNameError != null}
                    helperText={wasSubmit && driverNameError}
                    value={driverName}
                    onChange={(event) => setDriverName(event.target.value)}
                    inputProps={{ required: true }}
                    label="Driver name"
                    autoComplete="off"
                    name="driverName"
                    margin="none"
                    fullWidth
                  />
                </>
              )}
              {!requireChecklists && (
                <TextField
                  value={notes}
                  multiline
                  rows={3}
                  onChange={(event) => setNotes(event.target.value)}
                  placeholder="Other issues, notes, etc."
                  label="Notes"
                  autoComplete="off"
                  name="notes"
                  margin="none"
                  fullWidth
                />
              )}
            </>
          )}
          {step === 1 && workflow === WORKFLOW_XPO && (
            <ChecklistEditor
              required={wasSubmit}
              checklistItems={truckChecklistDefinition}
              value={truckChecklist}
              onChange={(key, value) => setTruckChecklist((state) => ({ ...state, [key]: value }))}
            />
          )}
          {step === 1 && workflow === WORKFLOW_ROADRUNNER && (
            <>
              <ChecklistEditor
                required={wasSubmit}
                checklistItems={trailerChecklistDefinition}
                value={trailerChecklist}
                onChange={(key, value) => setTrailerChecklist((state) => ({ ...state, [key]: value }))}
              />
              <MultifieldChecklistItemEditor
                columns={2}
                wasSubmit={wasSubmit}
                rootKey="brakesAndAxles"
                title="Brakes & axles"
                checklistItems={brakesAndAxlesChecklist}
                value={trailerChecklist.brakesAndAxles}
                onChange={(key, value) =>
                  setTrailerChecklist((state) => ({
                    ...state,
                    brakesAndAxles: { ...state.brakesAndAxles, [key]: value },
                  }))
                }
              />
              <MultifieldChecklistItemEditor
                columns={2}
                wasSubmit={wasSubmit}
                rootKey="tires"
                title="Tires"
                checklistItems={tiresChecklist}
                value={trailerChecklist.tires}
                onChange={(key, value) =>
                  setTrailerChecklist((state) => ({
                    ...state,
                    tires: { ...state.tires, [key]: value },
                  }))
                }
              />
              <TextField
                value={notes}
                multiline
                rows={3}
                onChange={(event) => setNotes(event.target.value)}
                placeholder="Other issues, notes, etc."
                label="Notes"
                autoComplete="off"
                name="notes"
                margin="none"
                fullWidth
              />
            </>
          )}
          {step === 2 && (
            <>
              <ChecklistEditor
                required={wasSubmit}
                checklistItems={trailerChecklistDefinition}
                value={trailerChecklist}
                onChange={(key, value) => setTrailerChecklist((state) => ({ ...state, [key]: value }))}
              />
              <TextField
                value={notes}
                multiline
                rows={3}
                onChange={(event) => setNotes(event.target.value)}
                placeholder="Other issues, notes, etc."
                label="Notes"
                autoComplete="off"
                name="notes"
                margin="none"
                fullWidth
              />
            </>
          )}
          <div className={classes.spacer} />
          <MobileStepper
            className={classes.stepper}
            steps={maxSteps}
            position="static"
            activeStep={step}
            nextButton={
              step === maxSteps - 1 ? (
                <Button onClick={() => handleSubmit()} size="large" variant="contained" color="secondary">
                  <AsyncActionLabel label="Save" fetchingLabel="Saving..." isFetching={isFetching} />
                </Button>
              ) : (
                <Button size="small" onClick={() => setStep((s) => (s === maxSteps - 1 ? s : s + 1))}>
                  Next
                  <NextIcon />
                </Button>
              )
            }
            backButton={
              <Button size="small" onClick={() => setStep((s) => (s === 0 ? s : s - 1))} disabled={step === 0}>
                <BackIcon />
                Back
              </Button>
            }
          />
        </div>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    initialYardId: getDefaultYardId(state),
    availableYards: getYards(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(CheckupWizard)));
