import { Table } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    tableLayout: 'auto',
    '& > thead > tr > th:first-child': {
      paddingLeft: theme.spacing(1),
    },
    '& > tbody > tr > td:first-child': {
      paddingLeft: theme.spacing(1),
    },
  },
}));

function TableAutoLayout({ ...rest }) {
  const classes = useStyles();
  return <Table.Table {...rest} style={{ tableLayout: 'auto' }} className={classes.root} />;
}

export default TableAutoLayout;
