import { withStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ProfileIcon from '@material-ui/icons/Person';
import UserAvatar from 'components/user/UserAvatar';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions, getUserCompany, getUserEmail, getUserName } from 'reducers/auth';

import { Button, Grid, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > div + div': {
      marginTop: theme.spacing(3),
    },
  },
  textContainer: {
    maxWidth: 300,
    overflowX: 'hidden',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.contrastText,
  },
});

function UserInfoMenu({ classes, email, name, company, onLogoutClick }) {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <UserAvatar />
        </Grid>
        <Grid item className={classes.textContainer}>
          <Typography noWrap>
            <strong>{name}</strong>
          </Typography>
          <Typography noWrap>{email}</Typography>
          <Typography noWrap variant="body2" color="textSecondary">
            {company}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justify="space-between">
        <Grid item xs={5} className={classes.buttonContainer}>
          <Button fullWidth variant="contained" component={Link} to="/profile" color="secondary">
            <ProfileIcon className={classes.icon} />
            Profile
          </Button>
        </Grid>
        <Grid item xs={5} className={classes.buttonContainer}>
          <Button fullWidth variant="contained" component={Link} to="/" onClick={onLogoutClick} color="secondary">
            <LogoutIcon className={classes.icon} />
            Sign out
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    name: getUserName(state),
    company: getUserCompany(state),
  };
}

export default connect(mapStateToProps, { onLogoutClick: actions.logout })(withStyles(styles)(UserInfoMenu));
