import { TextField, Button, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import * as api from 'api/user';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import SetTitle from 'components/common/SetTitle';
import BrandedFormPage, { BLOCKS_SPACING, MOBILE_BREAKPOINT } from 'components/pages/BrandedFormPage';
import MobileLogo from 'components/pages/MobileLogo';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 272,
    maxWidth: 480,
  },
  error: {
    marginTop: theme.spacing(3),
  },
  form: {
    marginTop: 70,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(BLOCKS_SPACING),
    },
  },
  submit: {
    whiteSpace: 'nowrap',
  },
});

function ForgotPassword({ classes, location }) {
  const { email: initialEmail = '' } = parse(location.search, { ignoreQueryPrefix: true });

  const [email, setEmail] = useState(initialEmail);
  const [isFetching, setIsFetching] = useState(false);
  const [wasSubmit, setWasSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEmail(initialEmail);
    setIsFetching(false);
    setWasSubmit(false);
    setSuccess(false);
  }, [location, initialEmail]);

  const isValidEmail = /^\S+@\S+$/.test(email);
  const emailError = wasSubmit && !isValidEmail ? 'Incorrect email' : '';

  async function request() {
    try {
      setIsFetching(true);

      await api.requestPasswordReset(email);

      setIsFetching(false);
      setSuccess(true);
    } catch (e) {
      setIsFetching(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!isFetching) {
      setWasSubmit(true);
      if (isValidEmail) {
        setIsFetching(true);
        request();
      }
    }
  }

  if (success) {
    return (
      <BrandedFormPage>
        <div className={classes.root}>
          <SetTitle title="Forgot password" />
          <MobileLogo />
          <Typography component="h1" variant="h6" gutterBottom>
            Forgot password? | omniX
          </Typography>
          <Typography className={classes.form} variant="subtitle1">
            We sent an email to <strong>{email}</strong> with further instructions.
          </Typography>
          <br />
          <Typography component="p" variant="subtitle1">
            Didn&apos;t receive the email yet?
            <br />
            Please check your Spam folder, or{' '}
            <MuiLink to={`/forgot-password?email=${email}`} component={Link}>
              try again
            </MuiLink>
            .
          </Typography>
          <br />
          <Typography component="p" variant="subtitle1">
            Return to the{' '}
            <MuiLink component={Link} to="/">
              home page
            </MuiLink>
            .
          </Typography>
        </div>
      </BrandedFormPage>
    );
  }

  return (
    <BrandedFormPage>
      <div className={classes.root}>
        <SetTitle title="Forgot password" />
        <MobileLogo />
        <Typography component="h1" variant="h6" gutterBottom>
          Forgot password? | omniX
        </Typography>
        <Typography variant="subtitle1">
          To reset a password please enter your email, we&apos;ll send you further instructions
        </Typography>
        <Grid
          component="form"
          container
          direction="column"
          onSubmit={handleSubmit}
          spacing={BLOCKS_SPACING}
          className={classes.form}
        >
          <Grid item>
            <TextField
              value={email}
              onChange={event => setEmail(event.target.value)}
              disabled={isFetching}
              error={emailError !== ''}
              helperText={emailError}
              label="Email"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus={initialEmail === ''}
              margin="none"
              variant="outlined"
              inputProps={{ required: true }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item container>
            <Grid item sm={7} xs={12}>
              <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
                <AsyncActionLabel label="Send" fetchingLabel="Sending..." isFetching={isFetching} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </BrandedFormPage>
  );
}

export default withRouter(withStyles(styles)(ForgotPassword));
