import axios from 'axios';
import { checkInApiPath, getApiPathForType, getTitleForType, TYPE_CHECK_IN } from 'components/checkup/utils';
import history from 'components/history';
import { get, upperFirst } from 'lodash';
import { actions, types } from 'reducers/checkup/create';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* searchAlreadyInside(yardId, type, serial) {
  if (!serial) {
    return null;
  }

  const params = { query: '*', limit: 1, sort: [{ name: 'createdAt', order: 'DESC' }] };
  params.filter = {
    and: {
      items: [
        { term: { name: `${type}Serial`, value: serial } },
        { term: { name: 'yardId', value: yardId } },
        {
          term: {
            name: `${type}Inside`,
            value: true,
          },
        },
      ],
    },
  };

  return yield call(axios.post, `${checkInApiPath}/search`, params);
}

function* createEntity({ entityType, entity, force }) {
  try {
    const apiPath = getApiPathForType(entityType);

    const { yardId, trailerSerial, containerSerial } = entity;
    if (entityType === TYPE_CHECK_IN && !force) {
      const [trailerResponse, containerResponse] = yield all([
        searchAlreadyInside(yardId, 'trailer', trailerSerial),
        searchAlreadyInside(yardId, 'container', containerSerial),
      ]);
      const trailer = get(trailerResponse, 'data.items[0]');
      const container = get(containerResponse, 'data.items[0]');
      if (container || trailer) {
        yield put(actions.warnAlreadyInside(trailer, container));
        return;
      }
    }

    const response = yield call(axios.post, apiPath, entity);

    yield put(actions.createSuccess(entityType, response.data));

    const title = upperFirst(getTitleForType(entityType));
    yield put(snackActions.set(`${title} saved`, snackVariants.success));
    history.push('/add');
  } catch (e) {
    yield put(actions.createFailure(e));
  }
}

export default function* create() {
  yield takeLatest(types.CREATE_REQUEST, createEntity);
}
