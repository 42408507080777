import axios from 'axios';

export function recognizeText(imageBlob, width, height) {
  const data = new FormData();
  data.append(
    'recognitionSettings',
    new Blob(
      [
        JSON.stringify({
          collapseSpaces: true,
          recognitionBox: { x1: 0, y1: 0, x2: width - 1, y2: height - 1 },
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );
  data.append('image', imageBlob);
  return axios.post('/apigw/v1/recognize/text', data);
}
