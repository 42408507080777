import React, { useState } from 'react';
import { get } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import { observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  primaryButton: {
    minWidth: 170,
  },
}));

class State {
  loading = false;
  errorText = '';

  constructor() {
    makeAutoObservable(this);
  }

  onResetError = () => {
    this.errorText = '';
  };

  async onSubmit(entityId, entity, isNew, callback) {
    let response;
    try {
      this.loading = true;
      response = await (isNew
        ? axios.post('/apigw/v1/ssline', entity)
        : axios.put(`/apigw/v1/ssline/${entityId}`, entity));
      callback(entityId, response.data, isNew);
    } catch (e) {
      const errors = get(e, 'response.data.errors', []);
      const hasDuplicateError = errors.some((error) => error.code === 1014);
      this.errorText = hasDuplicateError
        ? 'SS line with this code already exists'
        : 'Failed to save SS line. Please try again later';
    } finally {
      this.loading = false;
    }
  }
}

const codeRegexp = new RegExp('^[A-Z0-9]{4}$', 'i');

function SSLineEdit({ isNew, initialEntity, onClose, fullScreen, onSaveSuccess }) {
  const classes = useStyles();
  const [state] = useState(new State());
  const [code, setCode] = useState(get(initialEntity, 'code', ''));
  const [fullName, setFullName] = useState(get(initialEntity, 'fullName', ''));
  const [wasSubmit, setWasSubmit] = useState(false);
  const operation = isNew ? 'Create' : 'Edit';
  const codeError = !codeRegexp.test(code) ? 'Incorrect SS line code' : null;
  const fullNameError = !fullName.trim() ? 'Name is required' : null;

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!codeError && !fullNameError) {
      doSubmit();
    }
  }

  function doSubmit() {
    const entity = {
      ...initialEntity,
      code,
      fullName,
    };

    let entityId = initialEntity?.id;
    state.onSubmit(entityId, entity, isNew, onSaveSuccess);
  }

  return (
    <>
      <Dialog
        PaperProps={{
          id: 'ssline-editor-dialog-paper',
          className: classes.paperRoot,
        }}
        open={true}
        fullScreen={fullScreen}
        aria-labelledby="ssline-editor-dialog-title"
      >
        <DialogTitle id="ssline-editor-dialog-title" className={classes.title} disableTypography>
          <Typography variant="h6">{operation} SS line</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            value={code}
            onChange={(event) => setCode(event.target.value?.toUpperCase())}
            id="code"
            label="Code"
            autoComplete="off"
            name="code"
            margin="none"
            error={wasSubmit && codeError != null}
            helperText={wasSubmit && codeError}
            placeholder="4 letters owner prefix"
            inputProps={{ required: true }}
            fullWidth
          />
          <TextField
            value={fullName}
            onChange={(event) => setFullName(event.target.value)}
            id="fullName"
            label="Name"
            autoComplete="off"
            name="fullName"
            margin="none"
            error={wasSubmit && fullNameError != null}
            helperText={wasSubmit && fullNameError}
            inputProps={{ required: true }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={state.loading} variant="text" color="default">
            Cancel
          </Button>
          <Button
            className={classes.primaryButton}
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="secondary"
          >
            <AsyncActionLabel label="Save" fetchingLabel="Saving..." isFetching={state.loading} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        transitionDuration={0}
        open={state.errorText !== ''}
        onClose={state.onResetError}
        aria-labelledby="ssline-edit-error-dialog-title"
        aria-describedby="ssline-edit-error-dialog-description"
      >
        <DialogTitle id="ssline-edit-wizard-error-dialog-title">Save failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="ssline-edit-error-dialog-description">{state.errorText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={state.onResetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withMobileDialog()(observer(SSLineEdit));
