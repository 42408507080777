import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  withStyles,
  TextField,
} from '@material-ui/core';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import RoleWithTooltip from 'components/common/RoleWithTooltip';
import toJS from 'components/common/toJS';
import YardsPicker from 'components/pickers/YardsPicker';
import { cloneDeep, difference, get, pickBy, sortBy, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useDidUpdate } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { getDefaultYardId, getYardCompanies, getYards, isSuperAdmin } from 'reducers/auth';

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  primaryButton: {
    minWidth: 170,
  },
});

function getYardsError(yardIds, role, superAdmin) {
  if (role === 'guard' && yardIds.length !== 1) {
    return 'Guard should have a single assigned yard';
  }
  return !superAdmin && !yardIds.length ? 'Yards are required' : null;
}

function UserEdit({
  classes,
  onClose,
  isSuperAdmin,
  initialEntity,
  availableYards,
  yardCompanies,
  isFetching,
  isNew,
  error,
  resetError,
  onSubmit,
}) {
  const initialCompany = get(initialEntity, 'company');
  let availableCompanies = yardCompanies;
  if (initialCompany) {
    availableCompanies = cloneDeep([...availableCompanies, initialCompany]);
    availableCompanies = sortBy(uniqBy(availableCompanies, 'id'), company => company.name.toLowerCase());
  }

  const initialCompanyId = availableCompanies.length === 1 ? availableCompanies[0].id : '';
  const [name, setName] = useState(get(initialEntity, 'name', ''));
  const [email, setEmail] = useState(get(initialEntity, 'email', ''));
  const initialSuperAdmin = get(initialEntity, 'superAdmin', false);
  const defaultRole = initialSuperAdmin ? 'superadmin' : 'manager';
  const initialRole = get(initialEntity, 'role', 'manager');
  const [role, setRole] = useState(initialRole ? initialRole : defaultRole);
  const [companyId, setCompanyId] = useState(get(initialEntity, 'company.id', initialCompanyId));
  const [yardIds, setYardIds] = useState(get(initialEntity, 'yards', []).map(yard => yard.id));
  const [wasSubmit, setWasSubmit] = useState(false);
  const superAdmin = role === 'superadmin';
  const nameError = !name.trim() ? 'Name is required' : null;
  const emailError = !email.trim() ? 'Email is required' : null;
  const companyError = !companyId ? 'Company is required' : null;
  const yardsError = getYardsError(yardIds, role, superAdmin);
  const availableYardsFiltered = companyId ? pickBy(availableYards, yard => get(yard, 'company.id') === companyId) : {};
  get(initialEntity, 'yards', []).forEach(yard => {
    availableYardsFiltered[yard.id] = yard;
  });
  const readOnlyYards = isSuperAdmin
    ? []
    : difference(Object.keys(availableYardsFiltered), Object.keys(availableYards));
  useDidUpdate(() => setYardIds([]), [companyId]);

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!nameError && !emailError && !companyError && !yardsError) {
      doSubmit();
    }
  }

  function doSubmit() {
    const resolvedRole = superAdmin ? null : role;
    const resolvedYards = superAdmin ? [] : yardIds;
    const entity = {
      ...initialEntity,
      name,
      email,
      superAdmin,
      role: resolvedRole,
      companyId,
      yardIds: resolvedYards,
    };

    let entityId = initialEntity.id;
    onSubmit(entityId, entity, isNew);
  }

  return (
    <>
      <Dialog
        transitionDuration={0}
        open={!!error}
        onClose={resetError}
        aria-labelledby="user-edit-error-dialog-title"
        aria-describedby="user-edit-error-dialog-description"
      >
        <DialogTitle id="user-edit-wizard-error-dialog-title">Save failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="user-edit-error-dialog-description">{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContent className={classes.content}>
        <TextField
          value={name}
          onChange={event => setName(event.target.value)}
          id="name"
          label="Full name"
          autoComplete="name"
          name="name"
          margin="none"
          error={wasSubmit && nameError != null}
          helperText={wasSubmit && nameError}
          inputProps={{ required: true }}
          fullWidth
        />
        <TextField
          value={email}
          disabled={!isNew}
          onChange={event => setEmail(event.target.value)}
          id="email"
          label="Email"
          autoComplete="email"
          type="email"
          name="email"
          margin="none"
          error={wasSubmit && emailError != null}
          helperText={wasSubmit && emailError}
          inputProps={{ required: true }}
          fullWidth
        />
        <TextField
          select
          label="Role"
          id="role"
          name="role"
          margin="none"
          fullWidth
          value={role}
          onChange={event => setRole(event.target.value)}
        >
          <MenuItem value="guard">
            <RoleWithTooltip value="guard" />
          </MenuItem>
          <MenuItem divider value="supervisor">
            <RoleWithTooltip value="supervisor" />
          </MenuItem>
          <MenuItem value="manager">
            <RoleWithTooltip value="manager" />
          </MenuItem>
          {isSuperAdmin && (
            <MenuItem value="superadmin">
              <RoleWithTooltip value="superadmin" />
            </MenuItem>
          )}
        </TextField>
        <TextField
          select
          disabled={!isNew}
          label="Company"
          id="company"
          name="company"
          margin="none"
          error={wasSubmit && companyError != null}
          helperText={wasSubmit && companyError}
          fullWidth
          value={companyId}
          onChange={event => setCompanyId(event.target.value)}
        >
          {availableCompanies.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        {!superAdmin && (
          <YardsPicker
            id="yardIds"
            name="yardIds"
            margin="none"
            error={wasSubmit && yardsError != null}
            helperText={wasSubmit && yardsError}
            fullWidth
            value={yardIds}
            onChange={value => setYardIds(value)}
            availableYards={availableYardsFiltered}
            readOnlyYards={readOnlyYards}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isFetching} variant="text" color="default">
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="secondary"
        >
          <AsyncActionLabel label="Save" fetchingLabel="Saving..." isFetching={isFetching} />
        </Button>
      </DialogActions>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isSuperAdmin: isSuperAdmin(state),
    initialYardId: getDefaultYardId(state),
    availableYards: getYards(state),
    yardCompanies: getYardCompanies(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(UserEdit)));
