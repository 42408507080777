import Activation from 'components/activation/Activation';
import ScrollToTop from 'components/common/ScrollToTop';
import history from 'components/history';
import Login from 'components/login/Login';
import Main from 'components/Main';
import SnackbarNotifications from 'components/snackbar/SnackbarNotification';
import SnackbarOfflineStatus from 'components/snackbar/SnackbarOfflineStatus';
import ForgotPassword from 'components/user/ForgotPassword';
import ResetPassword from 'components/user/ResetPassword';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import { isAuthenticated } from 'reducers/auth';
import { CssBaseline } from '@material-ui/core';

function AppContent({ authenticated }) {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/activate" component={Activation} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />

        {authenticated ? <Route component={Main} /> : <Route component={Login} />}
      </Switch>
      <SnackbarNotifications />
      <SnackbarOfflineStatus />
    </>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
  };
}

const AppContentContainer = withRouter(connect(mapStateToProps, null)(AppContent));

function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <CssBaseline />
        <AppContentContainer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
