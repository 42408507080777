import PropTypes from 'prop-types';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

function Reparentable({ el }) {
  const ref = useRef(null);
  useLayoutEffect(() => {
    const node = ref.current;
    node.appendChild(el);

    return () => {
      node.removeChild(el);
    };
  }, [el]);
  return <div ref={ref} />;
}

function MasonryLayout({ columns, children, gap }) {
  const childContainers = useMemo(() => {
    const containers = [];
    for (let i = 0; i < children.length; i += 1) {
      containers.push(document.createElement('div'));
    }
    return containers;
  }, [children.length]);

  const columnWrapper = {};
  const result = [];

  // create columns
  for (let i = 0; i < columns; i += 1) {
    columnWrapper[`column${i}`] = [];
  }

  // divide children into columns
  for (let i = 0; i < children.length; i += 1) {
    const columnIndex = i % columns;
    columnWrapper[`column${columnIndex}`].push(
      <div key={children[i].key || i} style={{ marginBottom: `${gap}px` }}>
        <Reparentable key={children[i].key || i} el={childContainers[i]} />
      </div>
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columns; i += 1) {
    result.push(
      <div
        key={i}
        style={{
          marginLeft: `${i > 0 ? gap : 0}px`,
          flex: 1,
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      {children.map((child, i) => createPortal(child, childContainers[i]))}
      {result}
    </div>
  );
}

MasonryLayout.propTypes = {
  columns: PropTypes.number.isRequired,
  gap: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

export default MasonryLayout;
