import { prepareListItems } from 'components/checkup/utils';
import { fromJS } from 'immutable';
import { types as editTypes } from 'reducers/checkup/edit';
import { types as deleteTypes } from 'reducers/entity/delete';

export const types = {
  SEARCH_REQUEST: 'CHECKUP/LIST/SEARCH_REQUEST',
  SEARCH_SUCCESS: 'CHECKUP/LIST/SEARCH_SUCCESS',
  SEARCH_FAILURE: 'CHECKUP/LIST/SEARCH_FAILURE',
  RESET_ERROR: 'CHECKUP/LIST/RESET_ERROR',
  EXPORT_REQUEST: 'CHECKUP/LIST/EXPORT_REQUEST',
  EXPORT_SUCCESS: 'CHECKUP/LIST/EXPORT_SUCCESS',
  EXPORT_FAILURE: 'CHECKUP/LIST/EXPORT_FAILURE',
  RESET: 'CHECKUP/LIST/RESET',
};

export const initialState = fromJS({
  items: null,
  paging: null,
  isFetching: false,
  isFetchingToAppend: false,
  exportIsFetching: false,
  error: false,
});

export default function list(state = initialState, action) {
  switch (action.type) {
    case editTypes.UPDATE_SUCCESS: {
      const { entity } = action;
      const { id } = entity;
      const items = state.get('items');
      const itemIndex = items ? items.findIndex((item) => item.get('id') === id) : -1;
      if (itemIndex !== -1) {
        return state.withMutations((s) => {
          s.set('items', items.set(itemIndex, fromJS(entity)));
        });
      }
      return state;
    }
    case deleteTypes.DELETE_SUCCESS: {
      const { entity } = action;
      const { id } = entity;
      const items = state.get('items');
      if (items && !items.isEmpty()) {
        return state.withMutations((s) => {
          s.set(
            'items',
            items.filter((item) => item.get('id') !== id)
          );
        });
      }
      return state;
    }
    case types.SEARCH_REQUEST:
      return state.withMutations((s) => {
        s.set('isFetching', true);
        s.set('isFetchingToAppend', action.append);
        if (!action.append) {
          s.set('items', null);
          s.set('paging', null);
        }
      });
    case types.SEARCH_SUCCESS:
      return state.withMutations((s) => {
        const { items, paging } = action;
        const presentCount = s.get('items') ? s.get('items').size : 0;
        if (items.length === 0) {
          paging.count = presentCount;
        }
        s.set('isFetching', false).set('paging', fromJS(paging));
        if (action.append && s.get('items')) {
          s.set('items', s.get('items').concat(fromJS(prepareListItems(items))));
        } else {
          s.set('items', fromJS(prepareListItems(items)));
        }
      });
    case types.SEARCH_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('error', true);
        if (!action.append) {
          s.set('items', null).set('paging', null);
        }
      });
    case types.EXPORT_REQUEST:
      return state.set('exportIsFetching', true);
    case types.EXPORT_SUCCESS:
      return state.set('exportIsFetching', false);
    case types.EXPORT_FAILURE:
      return state.set('exportIsFetching', false);
    case types.RESET_ERROR:
      return state.set('error', false);
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  searchRequest: (entityType, startDate, endDate, yardId, query, append) => ({
    type: types.SEARCH_REQUEST,
    entityType,
    startDate,
    endDate,
    yardId,
    query,
    append,
  }),
  searchSuccess: (entityType, items, paging, append) => ({
    type: types.SEARCH_SUCCESS,
    entityType,
    items,
    paging,
    append,
  }),
  searchFailure: (error) => ({ type: types.SEARCH_FAILURE, error }),
  resetError: () => ({ type: types.RESET_ERROR }),
  exportRequest: (entityType, startDate, endDate, yardId, query, filename) => ({
    type: types.EXPORT_REQUEST,
    entityType,
    startDate,
    endDate,
    yardId,
    query,
    filename,
  }),
  exportSuccess: () => ({ type: types.EXPORT_SUCCESS }),
  exportFailure: (error) => ({ type: types.EXPORT_FAILURE, error }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = (state) => state.get('checkup').get('list');
export const getItems = (state) => getRoot(state).get('items');
export const getPaging = (state) => getRoot(state).get('paging');
export const getTotalMatchedCount = (state) => getPaging(state) && getPaging(state).get('count');
export const getIsFetching = (state) => getRoot(state).get('isFetching');
export const getIsFetchingToAppend = (state) => getRoot(state).get('isFetchingToAppend');
export const getExportIsFetching = (state) => getRoot(state).get('exportIsFetching');
export const getError = (state) => getRoot(state).get('error');
