import axios from 'axios';
import { getApiPathForType, getTitleForType } from 'components/checkup/utils';
import { upperFirst } from 'lodash';
import { actions, types } from 'reducers/checkup/edit';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, put, takeLatest } from 'redux-saga/effects';

function* loadEntity({ entityId, entityType }) {
  try {
    const apiPath = getApiPathForType(entityType);
    const response = yield call(axios.get, `${apiPath}/${entityId}`);
    yield put(actions.loadSuccess(entityId, entityType, response.data));
  } catch (e) {
    yield put(actions.loadFailure(e));
  }
}

function* updateEntity({ entityId, entityType, entity }) {
  try {
    const apiPath = getApiPathForType(entityType);
    const response = yield call(axios.put, `${apiPath}/${entityId}`, entity);
    yield put(actions.updateSuccess(entityId, entityType, response.data));

    const title = upperFirst(getTitleForType(entityType));
    yield put(snackActions.set(`${title} updated`, snackVariants.success));
  } catch (e) {
    yield put(actions.updateFailure(e));
  }
}

export default function* edit() {
  yield takeLatest(types.LOAD_REQUEST, loadEntity);
  yield takeLatest(types.UPDATE_REQUEST, updateEntity);
}
