import { apiPath } from 'api/user';
import axios from 'axios';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { actions, types } from 'reducers/user/edit';
import { actions as userListActions } from 'reducers/user/list';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

function* loadEntity({ entityId }) {
  try {
    const response = yield call(axios.get, `${apiPath}/${entityId}`);
    yield put(actions.loadSuccess(entityId, response.data));
  } catch (e) {
    yield put(actions.loadFailure(e));
  }
}

function* updateEntity({ entityId, entity, isNew }) {
  try {
    const response = isNew
      ? yield call(axios.post, apiPath, entity)
      : yield call(axios.put, `${apiPath}/${entityId}`, entity);
    if (isNew) {
      yield delay(1000);
      yield put(userListActions.searchRequest());
    }
    yield put(actions.updateSuccess(entityId, response.data));

    const message = isNew ? 'User created' : 'User updated';
    yield put(snackActions.set(message, snackVariants.success));
  } catch (e) {
    yield put(actions.updateFailure(e));
  }
}

export default function* edit() {
  yield takeLatest(types.LOAD_REQUEST, loadEntity);
  yield takeLatest(types.UPDATE_REQUEST, updateEntity);
}
