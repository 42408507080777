import { UNPROCESSABLE_ENTITY } from 'http-status-codes';
import { fromJS } from 'immutable';
import { getErrorResponseStatus } from 'reducers/utils';

export const types = {
  UPDATE_REQUEST: 'PROFILE_PASSWORD/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROFILE_PASSWORD/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROFILE_PASSWORD/UPDATE_FAILURE',
  RESET: 'PROFILE_PASSWORD/RESET',
};

export const initialState = fromJS({
  isFetching: false,
  error: null,
});

export default function password(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_REQUEST:
      return state.withMutations(s => s.set('isFetching', true).set('error', null));
    case types.UPDATE_SUCCESS:
      return state.withMutations(s => s.set('isFetching', false));
    case types.UPDATE_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        const status = getErrorResponseStatus(action);
        if (status === UNPROCESSABLE_ENTITY) {
          s.set('error', 'Incorrect old password');
        }
        return s;
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  updateRequest: (oldPassword, newPassword) => ({ type: types.UPDATE_REQUEST, oldPassword, newPassword }),
  updateSuccess: () => ({ type: types.UPDATE_SUCCESS }),
  updateFailure: error => ({ type: types.UPDATE_FAILURE, error }),
  reset: () => ({ type: types.RESET }),
};

export const getPasswordState = state => state.get('profile').get('password');
export const isUpdateFetching = state => getPasswordState(state).get('isFetching');
export const getPasswordError = state => getPasswordState(state).get('error');
