import { withStyles } from '@material-ui/core';
import CheckupList from 'components/checkup/CheckupList';
import { TYPE_CHECK_OUT } from 'components/checkup/utils';
import React from 'react';

const styles = () => ({});

function CheckOutList() {
  return <CheckupList entityType={TYPE_CHECK_OUT} />;
}

export default withStyles(styles)(CheckOutList);
