import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import PhotoOkIcon from '@material-ui/icons/Check';
import TorchOffIcon from '@material-ui/icons/Close';
import TorchOnIcon from '@material-ui/icons/Highlight';
import CaptureIcon from '@material-ui/icons/PhotoCamera';
import PhotoRetryIcon from '@material-ui/icons/Refresh';
import BackIcon from '@material-ui/icons/Undo';
import { recognizeText } from 'api/recognition';
import FlatButton from 'components/common/FlatButton';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import screenfull from 'screenfull';

const controlsWidth = 170;

const styles = theme => ({
  video: {
    width: `100%`,
    height: `100%`,
    backgroundColor: '#000',
    objectFit: 'cover',
  },
  overlay: {
    zIndex: 1,
    position: 'fixed',
    top: '37%',
    left: '5%',
    width: `calc(90% - ${controlsWidth}px)`,
    height: '26%',
    boxShadow: '0px 0px 0px 2000px rgba(0, 0, 0, 0.3)',
    '&:before': {
      display: 'block',
      content: '""',
      width: 20,
      height: 20,
      position: 'absolute',
      top: -4,
      right: -4,
      borderTop: '4px solid #fff',
      borderRight: '4px solid #fff',
    },
    '&:after': {
      display: 'block',
      content: '""',
      width: 20,
      height: 20,
      position: 'absolute',
      top: -4,
      left: -4,
      borderTop: '4px solid #fff',
      borderLeft: '4px solid #fff',
    },
    '& > span:before': {
      display: 'block',
      content: '""',
      width: 20,
      height: 20,
      position: 'absolute',
      bottom: -4,
      left: -4,
      borderBottom: '4px solid #fff',
      borderLeft: '4px solid #fff',
    },
    '& > span:after': {
      display: 'block',
      content: '""',
      width: 20,
      height: 20,
      position: 'absolute',
      bottom: -4,
      right: -4,
      borderBottom: '4px solid #fff',
      borderRight: '4px solid #fff',
    },
  },
  controls: {
    zIndex: 1,
    height: '100%',
    backgroundColor: '#000',
    width: controlsWidth,
    position: 'fixed',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    height: '100%',
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sliderContainer: {
    width: 50,
    marginRight: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    appearance: 'slider-vertical',
    width: 50,
    height: '60%',
  },
  photoContainer: {
    position: 'fixed',
    width: `100%`,
    height: `100%`,
    backgroundColor: 'black',
  },
  photo: {
    position: 'relative',
    top: '37%',
    left: `5%`,
    width: `calc(90% - ${controlsWidth}px)`,
    height: '26%',
    objectFit: 'contain',
  },
});

export async function enterFullscreen() {
  if (screenfull.isEnabled && !screenfull.isFullscreen) {
    try {
      await screenfull.request();
      try {
        await window.screen.orientation.lock('landscape');
      } catch (e) {
        // ignore
      }
    } catch (err) {
      alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
    }
  }
}

function sleep(ms = 0) {
  return new Promise(r => setTimeout(r, ms));
}

function stopStream(stream) {
  stream && stream.getTracks().forEach(t => t.stop());
}

function TextRecognizer({ onChange, onClose, classes }) {
  const video = useRef(null);
  const canvas = useRef(null);
  const overlay = useRef(null);
  const [inProgress, setInProgress] = useState(false);
  const [track, setTrack] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [torch, setTorch] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);

  async function setupCapabilities(stream) {
    await sleep(500);

    const t = stream.getVideoTracks()[0];
    setTrack(t);

    const capabilities = t.getCapabilities();
    const settings = t.getSettings();

    if (capabilities.torch) {
      setTorch(settings.torch);
    }

    // Check whether zoom is supported or not.
    if ('zoom' in capabilities) {
      setZoom(settings.zoom);
    }
  }

  useLayoutEffect(() => {
    const listener = () => {
      if (!screenfull.isFullscreen) {
        onClose();
      }
    };
    if (screenfull.isEnabled) {
      screenfull.on('change', listener);
    }

    let videoNode;
    async function getMedia() {
      try {
        const constraints = {
          audio: false,
          video: { width: { ideal: 4096 }, height: { ideal: 2160 }, facingMode: 'environment' },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoNode = video.current;
        if (videoNode) {
          videoNode.srcObject = stream;
          videoNode.onplay = () => setupCapabilities(stream);
        } else {
          stopStream(stream);
        }
      } catch (err) {
        alert(`Error attempting to access camera: ${err.message} (${err.name})`);
      }
    }

    getMedia();

    return () => {
      videoNode && videoNode.srcObject && stopStream(videoNode.srcObject);
      if (screenfull.isEnabled) {
        screenfull.off('change', listener);
        screenfull.exit();
      }
    };
  }, [onClose]);
  useEffect(() => {
    if (track) {
      zoom !== null && track.applyConstraints({ advanced: [{ zoom }] });
      torch !== null && track.applyConstraints({ advanced: [{ torch }] });
    }
  }, [zoom, torch, track]);

  function takePicture() {
    setInProgress(true);

    const can = canvas.current;
    const context = can.getContext('2d');
    const { videoWidth, videoHeight } = video.current;

    const videoRect = video.current.getBoundingClientRect();
    const viewWidth = videoRect.width;
    const viewHeight = videoRect.height;

    const videoAr = videoWidth / videoHeight;
    const viewAr = viewWidth / viewHeight;
    let scaledVideoWidth = viewWidth;
    let scaledVideoHeight = viewHeight;
    if (videoAr > viewAr) {
      scaledVideoWidth = (videoWidth * viewHeight) / videoHeight;
    } else if (videoAr < viewAr) {
      scaledVideoHeight = (videoHeight * viewWidth) / videoWidth;
    }

    let scaledXStart = 0;
    let visibleVideoWidth = videoWidth;
    let scaledYStart = 0;
    let visibleVideoHeight = videoHeight;
    if (scaledVideoWidth > viewWidth) {
      const diff = scaledVideoWidth - viewWidth;
      scaledXStart = diff / 2;
      visibleVideoWidth = videoWidth * ((scaledVideoWidth - diff) / scaledVideoWidth);
    } else if (scaledVideoHeight > viewHeight) {
      const diff = scaledVideoHeight - viewHeight;
      scaledYStart = diff / 2;
      visibleVideoHeight = videoHeight * ((scaledVideoHeight - diff) / scaledVideoHeight);
    }

    const overlayRect = overlay.current.getBoundingClientRect();
    const photoWidth = visibleVideoWidth * (overlayRect.width / viewWidth);
    const photoHeight = visibleVideoHeight * (overlayRect.height / viewHeight);
    const videoX = videoWidth * ((scaledXStart + overlayRect.x) / scaledVideoWidth);
    const videoY = videoHeight * ((scaledYStart + overlayRect.y) / scaledVideoHeight);

    can.width = photoWidth;
    can.height = photoHeight;
    context.drawImage(video.current, videoX, videoY, photoWidth, photoHeight, 0, 0, photoWidth, photoHeight);
    const data = can.toDataURL('image/jpeg');
    setImage(data);

    setInProgress(false);
  }

  function recognizeImage() {
    if (!image) {
      return;
    }

    setInProgress(true);

    const can = canvas.current;
    can.toBlob(async blob => {
      try {
        const response = await recognizeText(blob, can.width, can.height);
        setInProgress(false);
        const { text } = response.data;
        if (!text) {
          setError(true);
          setImage(null);
        } else {
          onChange(text);
        }
      } catch (e) {
        setInProgress(false);
        setError(true);
        setImage(null);
      }
    }, 'image/jpeg');
  }

  const capabilities = track && track.getCapabilities();

  return (
    <Dialog open fullScreen>
      <video ref={video} autoPlay muted className={classes.video} />
      <canvas ref={canvas} style={{ display: 'none' }} />
      <div ref={overlay} className={classes.overlay}>
        <span />
      </div>
      <div className={classes.controls}>
        {!image && (
          <div className={classes.sliderContainer}>
            {zoom !== null && (
              <input
                type="range"
                className={classes.slider}
                value={zoom}
                min={capabilities.zoom.min}
                max={capabilities.zoom.max}
                step={capabilities.zoom.step}
                onChange={event => setZoom(event.target.value)}
              />
            )}
          </div>
        )}
        <div className={classes.buttons}>
          {!image && (
            <>
              <FlatButton
                onClick={() => {
                  screenfull.isEnabled && screenfull.exit();
                  onClose();
                }}
                icon={BackIcon}
              />
              <FlatButton
                inProgress={inProgress}
                onClick={() => {
                  takePicture();
                }}
                icon={CaptureIcon}
              />
              {torch !== null ? (
                <FlatButton
                  inProgress={inProgress}
                  onClick={() => setTorch(state => !state)}
                  icon={torch ? TorchOffIcon : TorchOnIcon}
                />
              ) : (
                <div style={{ height: 64 }} />
              )}
            </>
          )}
          {image && (
            <>
              <FlatButton onClick={() => setImage(null)} icon={PhotoRetryIcon} />
              <FlatButton
                inProgress={inProgress}
                onClick={() => {
                  recognizeImage();
                }}
                icon={PhotoOkIcon}
              />
            </>
          )}
        </div>
      </div>
      {image && (
        <div className={classes.photoContainer}>
          <img src={image} alt="" className={classes.photo} />
        </div>
      )}
      <Dialog
        open={error}
        onClose={() => setError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Recognition failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            omniX can't recognize any serial number on this picture, please try again
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(false)} variant="contained" color="secondary">
            Try again
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default withStyles(styles)(TextRecognizer);
