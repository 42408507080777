import { configure } from 'mobx';

configure({
  enforceActions: 'never',
});

// Configure MobX to auto batch all sync mutations without using action/runInAction
setTimeout(() => {
  configure({
    reactionScheduler: (f) => {
      setTimeout(f, 1);
    },
  });
}, 1);
