import { withStyles } from '@material-ui/core/styles';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import StaticTextField from 'components/common/StaticTextField';
import TimezonePicker from 'components/pickers/TimezonePicker';
import UserAvatar from 'components/user/UserAvatar';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getUserCompany, getUserEmail, getUserName, getUserTimezone } from 'reducers/auth';
import { actions, isUpdateFetching } from 'reducers/profile/details';

import { Button, Grid, Link as MuiLink, TextField, Typography } from '@material-ui/core';

const styles = theme => ({
  userAvatar: {
    marginTop: theme.spacing(1),
  },
});

function ProfileDetails({ classes, email, initialName, initialTimezone, company, onSubmit, isFetching }) {
  const [name, setName] = useState(initialName);
  const [timezone, setTimezone] = useState(initialTimezone);
  const [wasSubmit, setWasSubmit] = useState(false);
  const errorText = wasSubmit && name.trim() === '' ? "Name can't be empty" : '';

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (name.trim() !== '') {
      onSubmit(name, timezone);
    }
  }

  return (
    <Grid component="form" container spacing={3} direction="column" wrap="nowrap" onSubmit={handleSubmit}>
      <Grid item>
        <Typography variant="h6">Details</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" wrap="nowrap" spacing={1}>
          <Grid item>
            <StaticTextField value={<UserAvatar className={classes.userAvatar} size={80} />} label="Profile picture" />
          </Grid>
          <Grid item component={Typography} variant="body1" color="textPrimary">
            <MuiLink href="http://en.gravatar.com/emails/" target="_blank" rel="noopener noreferrer">
              Edit on Gravatar
            </MuiLink>
          </Grid>
        </Grid>
      </Grid>
      {company && (
        <Grid item>
          <StaticTextField value={company} label="Company" />
        </Grid>
      )}
      <Grid item>
        <StaticTextField value={email} label="Email" />
      </Grid>
      <Grid item>
        <TextField
          value={name}
          onChange={event => setName(event.target.value)}
          label="Full name"
          autoComplete="name"
          name="name"
          margin="none"
          error={errorText !== ''}
          helperText={errorText}
          inputProps={{ required: true }}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TimezonePicker value={timezone} onChange={event => setTimezone(event.target.value)} />
      </Grid>
      <Grid item xs={12} md={7}>
        <Button color="secondary" variant="contained" fullWidth type="submit">
          <AsyncActionLabel label="Update details" fetchingLabel="Updating..." isFetching={isFetching} />
        </Button>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    initialName: getUserName(state),
    initialTimezone: getUserTimezone(state),
    company: getUserCompany(state),
    isFetching: isUpdateFetching(state),
  };
}

export default connect(mapStateToProps, { onSubmit: actions.updateRequest })(withStyles(styles)(ProfileDetails));
