import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { Grid, Typography } from '@material-ui/core';

const styles = theme => ({
  headerLegend: {
    marginLeft: theme.spacing(1),
  },
  value: {
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  label: {
    lineHeight: 1,
  },
});

function TwoSidesWidgetHeader({ classes, left, right }) {
  return (
    <Grid item container alignItems="center">
      <Grid
        item
        xs={6}
        container
        alignItems="flex-start"
        wrap="nowrap"
        direction="column"
        style={{ borderLeft: `4px solid ${left.color}`, paddingLeft: 12 }}
      >
        <Typography variant="h4" component="span" className={classes.value}>
          {typeof left.value === 'number' ? left.value : <>&mdash;</>}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.label}>
          {left.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        container
        alignItems="flex-start"
        wrap="nowrap"
        direction="column"
        style={{ borderLeft: `4px solid ${right.color}`, paddingLeft: 12 }}
      >
        <Typography variant="h4" component="span" className={classes.value}>
          {typeof right.value === 'number' ? right.value : <>&mdash;</>}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.label}>
          {right.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TwoSidesWidgetHeader);
