import { withStyles } from '@material-ui/core';
import CheckupCreator from 'components/checkup/CheckupCreator';
import { TYPE_CHECK_IN } from 'components/checkup/utils';
import React from 'react';

const styles = () => ({});

function CheckIntWizard() {
  return <CheckupCreator entityType={TYPE_CHECK_IN} />;
}

export default withStyles(styles)(CheckIntWizard);
