import { CircularProgress, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

function CsvExportButton({ isFetching, onClick, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <Tooltip title="Export as CSV">
        <IconButton onClick={onClick} aria-label="Export as CSV">
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      {isFetching && <CircularProgress size={48} className={classes.progress} />}
    </div>
  );
}

export default CsvExportButton;
