import MovementsIcon from '@material-ui/icons/SwapHoriz';
import { chartAspectRatio } from 'components/dashboard/DashboardWidgets';
import TwoSidesWidgetHeader from 'components/dashboard/TwoSidesWidgetHeader';
import Widget from 'components/dashboard/Widget';
import WidgetChart from 'components/dashboard/WidgetChart';
import COLORS from 'components/style/colors';
import React from 'react';
import { FaSignInAlt as CheckInIcon, FaSignOutAlt as CheckOutIcon } from 'react-icons/fa';
import { AutoSizer } from 'react-virtualized';

const inColor = COLORS.BLUE;
const outColor = COLORS.LIGHT_BLUE;

function TotalMovementsWidget({ value, isFetching }) {
  const totalIn = value && value.totalIn;
  const totalOut = value && value.totalOut;
  const series = value && [
    { name: 'Check ins', value: totalIn, color: inColor, renderIcon: () => <CheckInIcon /> },
    { name: 'Check outs', value: totalOut, color: outColor, renderIcon: () => <CheckOutIcon /> },
  ];

  return (
    <Widget icon={MovementsIcon} title="Total movements">
      <>
        <TwoSidesWidgetHeader
          left={{ name: 'Check ins', value: totalIn, color: inColor }}
          right={{ name: 'Check outs', value: totalOut, color: outColor }}
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / chartAspectRatio;

            const options = {
              tooltip: {
                trigger: 'item',
                axisPointer: {
                  type: 'shadow',
                },
              },
              grid: {
                left: 0,
                top: 20,
                right: 10,
                bottom: 10,
                containLabel: true,
              },
              xAxis: [
                {
                  type: 'category',
                  data: [],
                  show: false,
                },
              ],
              yAxis: [
                {
                  type: 'value',
                  axisTick: {
                    show: false,
                  },
                  axisLine: {
                    show: false,
                  },
                  axisLabel: {
                    margin: 10,
                  },
                },
              ],
              series:
                series &&
                series.map((s) => ({
                  ...s,
                  type: 'bar',
                  itemStyle: { color: s.color, ...s.itemStyle },
                  data: [s.value],
                  barMaxWidth: '15%',
                  barMaxHeight: height - 30,
                })),
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

export default React.memo(TotalMovementsWidget);
