import { all } from 'redux-saga/effects';
import auth from 'sagas/auth';
import checkup from 'sagas/checkup';
import dashboard from 'sagas/dashboard';
import entity from 'sagas/entity';
import inside from 'sagas/inside';
import network from 'sagas/network';
import profile from 'sagas/profile';
import user from 'sagas/user';

export default function* rootSaga() {
  yield all([auth(), profile(), network(), dashboard(), checkup(), inside(), user(), entity()]);
}
