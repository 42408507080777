import CameraIcon from '@material-ui/icons/PhotoCamera';
import TextRecognizer, { enterFullscreen } from 'components/add/TextRecognizer';
import React, { useCallback, useState } from 'react';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';

function TextRecognitionField({ onChange, value, helperText, InputProps, ...rest }) {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <TextField
        {...rest}
        value={value}
        helperText={helperText || 'Take a picture or enter it manually'}
        onChange={event => onChange(event.target.value.toUpperCase())}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  enterFullscreen();
                  setOpen(true);
                }}
              >
                <CameraIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {open && (
        <TextRecognizer
          onClose={onClose}
          onChange={value => {
            onChange(value.toUpperCase());
            setOpen(false);
          }}
        />
      )}
    </>
  );
}

export default TextRecognitionField;
