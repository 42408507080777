import { withStyles } from '@material-ui/core';
import CheckupParamsPicker from 'components/checkup/CheckupParamsPicker';
import SetTitle from 'components/common/SetTitle';
import useDateRange, { dateFormat } from 'components/common/useDateRange';
import useQueryParam from 'components/common/useQueryParam';
import useYardWorkflow from 'components/common/useYardWorkflow';
import DashboardWidgets from 'components/dashboard/DashboardWidgets';
import MovementsOverview, { prepareDateRange } from 'components/dashboard/MovementsOverview';
import React, { useCallback, useEffect, useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultYardId } from 'reducers/auth';
import { actions } from 'reducers/dashboard/checkup';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
});

function Dashboard({ classes, location, history, initialYardId, searchRequest, statsRequest, onUnmount }) {
  useWillUnmount(onUnmount);
  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const [searchForceRefresh, setSearchForceRefresh] = useState(false);
  const [yardId, setYardId] = useState(initialYardId);
  const [startDate, setStartDate, endDate, setEndDate] = useDateRange(location, history);
  useEffect(() => {
    const [sd, ed] = prepareDateRange(startDate, endDate);
    statsRequest(sd, ed, yardId);
  }, [startDate, endDate, yardId, statsRequest]);
  const doSearchRequest = useCallback(
    (append) => {
      const [sd, ed] = prepareDateRange(startDate, endDate);
      searchRequest(sd, ed, yardId, query, append);
    },
    [startDate, endDate, yardId, query, searchRequest]
  );
  useEffect(() => doSearchRequest(false), [doSearchRequest, searchForceRefresh]);
  useEffect(() => {
    localStorage.setItem('lastUsedYardId', yardId);
  }, [yardId]);
  const workflow = useYardWorkflow(yardId);

  return (
    <>
      <SetTitle title="Dashboard" />
      <div className={classes.root}>
        <CheckupParamsPicker
          startDate={startDate}
          onStartDateChange={(value) => setStartDate(value.format(dateFormat))}
          endDate={endDate}
          onEndDateChange={(value) => setEndDate(value.format(dateFormat))}
          yardId={yardId}
          onYardIdChange={(event) => setYardId(event.target.value)}
        />
        <DashboardWidgets workflow={workflow} />
        <MovementsOverview
          startDate={startDate}
          endDate={endDate}
          yardId={yardId}
          onSearch={() => setSearchForceRefresh((previous) => !previous)}
          onWaypointEnter={() => doSearchRequest(true)}
        />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    initialYardId: getDefaultYardId(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    statsRequest: actions.statsRequest,
    searchRequest: actions.searchRequest,
    onUnmount: actions.reset,
  })(withStyles(styles)(Dashboard))
);
