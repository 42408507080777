import { SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckupActionMenu from 'components/checkup/CheckupActionMenu';
import CheckupParamsPicker from 'components/checkup/CheckupParamsPicker';
import CheckupSearchHelperText from 'components/checkup/CheckupSearchHelperText';
import SerialWithChecklist from 'components/checkup/SerialWithChecklist';
import {
  getContainerVisitStatus,
  getTitleForType,
  getTrailerVisitStatus,
  noValue,
  resolveSSLineName,
  resolveYardNameById,
  trailerChecklistDefinition,
  truckChecklistDefinition,
  TYPE_CHECK_IN,
  WORKFLOW_ROADRUNNER,
} from 'components/checkup/utils';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import CsvExportButton from 'components/common/CsvExportButton';
import { GridRoot, TableContainer } from 'components/common/Grid';
import InlineLoadingIndicator from 'components/common/InlineLoadingIndicator';
import { momentUserTz } from 'components/common/momentUtils';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import TableAutoLayout from 'components/common/TableAutoLayout';
import TableToolbar from 'components/common/TableToolbar';
import toJS from 'components/common/toJS';
import useDateRange, { dateFormat } from 'components/common/useDateRange';
import useQueryParam from 'components/common/useQueryParam';
import useYardWorkflow from 'components/common/useYardWorkflow';
import { capitalize, get, upperFirst } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { getDefaultYardId } from 'reducers/auth';
import {
  actions,
  getError,
  getExportIsFetching,
  getIsFetching,
  getIsFetchingToAppend,
  getItems,
  getTotalMatchedCount,
} from 'reducers/checkup/list';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  tableContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    minHeight: 200,
  },
  addButton: {
    color: `${theme.palette.secondary.contrastText} !important`,
  },
}));

const defaultSorting = [{ columnName: 'createdAt', direction: 'desc' }];
const tableColumnExtensions = [
  { columnName: 'ssLine', wordWrapEnabled: true },
  { columnName: 'reportedBy', wordWrapEnabled: true },
  { columnName: 'createdAt', wordWrapEnabled: true },
  { columnName: 'containerVisit', wordWrapEnabled: true },
  { columnName: 'chassisVisit', wordWrapEnabled: true },
];

function prepareDateRange(startDate, endDate) {
  return [
    momentUserTz(startDate, dateFormat).startOf('day').utc().toISOString(),
    momentUserTz(endDate, dateFormat).endOf('day').utc().toISOString(),
  ];
}

function CheckupList({
  location,
  history,
  entityType,
  initialYardId,
  fixedDates,
  isFetching,
  isFetchingToAppend,
  exportIsFetching,
  error,
  value,
  totalMatched,
  onUnmount,
  onResetError,
  onSearch,
  onExport,
}) {
  const classes = useStyles();
  const titleLc = `${getTitleForType(entityType)}s`;
  const titleSingular = `${getTitleForType(entityType)}`;
  const title = upperFirst(titleLc);
  const timeColumnName = entityType === TYPE_CHECK_IN ? 'Entry time' : 'Exit time';
  const addNewUrl = entityType === TYPE_CHECK_IN ? '/add/check-in' : '/add/check-out';

  useWillUnmount(onUnmount);
  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const [yardId, setYardId] = useState(initialYardId);
  const [searchForceRefresh, setSearchForceRefresh] = useState(false);
  const workflow = useYardWorkflow(yardId);
  let [startDate, setStartDate, endDate, setEndDate] = useDateRange(location, history);
  if (fixedDates) {
    startDate = fixedDates.sd;
    endDate = fixedDates.ed;
  }
  useEffect(() => {
    localStorage.setItem('lastUsedYardId', yardId);
  }, [yardId]);
  const handleExportClick = useCallback(() => {
    if (!exportIsFetching) {
      const yardName = resolveYardNameById(yardId);
      const filename = `${yardName} ${startDate.replace(/\//g, '_')}${
        startDate === endDate ? '' : ` - ${endDate.replace(/\//g, '_')}`
      } ${title}.csv`;
      const [sd, ed] = prepareDateRange(startDate, endDate);
      onExport(entityType, sd, ed, yardId, query, filename);
    }
  }, [exportIsFetching, entityType, startDate, endDate, yardId, query, title, onExport]);
  const doSearchRequest = useCallback(
    (append) => {
      const [sd, ed] = prepareDateRange(startDate, endDate);
      onSearch(entityType, sd, ed, yardId, query, append);
    },
    [entityType, startDate, endDate, yardId, query, onSearch]
  );
  useEffect(() => doSearchRequest(false), [doSearchRequest, searchForceRefresh]);
  const columns = useMemo(
    () => [
      { name: 'action', title: ' ', getCellValue: (row) => <CheckupActionMenu workflow={workflow} entity={row} /> },
      {
        name: 'truckSerial',
        title: 'Tractor',
        getCellValue: (row) => (
          <SerialWithChecklist
            value={row.truckSerial}
            checklist={row.truckChecklist}
            checklistDefinition={truckChecklistDefinition}
          />
        ),
      },
      { name: 'containerSerial', title: 'Container', getCellValue: (row) => row.containerSerial || noValue },
      {
        name: 'trailerSerial',
        title: 'Chassis',
        getCellValue: (row) => (
          <SerialWithChecklist
            value={row.trailerSerial || noValue}
            checklist={row.trailerChecklist}
            checklistDefinition={trailerChecklistDefinition}
          />
        ),
      },
      {
        name: 'containerSize',
        title: 'Container size',
        getCellValue: (row) => (row.containerSize ? `${row.containerSize} ft` : noValue),
      },
      ...(entityType === TYPE_CHECK_IN
        ? [
            {
              name: 'trailerSize',
              title: 'Chassis size',
              getCellValue: (row) => (row.trailerSize ? `${row.trailerSize} ft` : noValue),
            },
          ]
        : []),
      {
        name: 'loadType',
        title: 'Load',
        getCellValue: (row) => (row.loadType ? capitalize(row.loadType) : noValue),
      },
      {
        name: 'containerSeal',
        title: 'Seal',
        getCellValue: (row) => row.containerSeal || noValue,
      },
      {
        name: 'ssLine',
        title: 'SS Line',
        getCellValue: (row) => (row.containerSerial ? resolveSSLineName(row.containerSerial) : noValue),
      },
      {
        name: 'createdAt',
        title: timeColumnName,
        getCellValue: (row) => momentUserTz(row.createdAt).format('MM/DD/YYYY HH:mm'),
      },
      ...(entityType === TYPE_CHECK_IN
        ? [
            {
              name: 'containerVisit',
              title: 'Container visit',
              getCellValue: (row) => getContainerVisitStatus(row),
            },
            {
              name: 'chassisVisit',
              title: 'Chassis visit',
              getCellValue: (row) => getTrailerVisitStatus(row),
            },
          ]
        : []),
      ...(workflow === WORKFLOW_ROADRUNNER
        ? [
            {
              name: 'truckingCompany',
              title: 'Trucking company',
            },
            {
              name: 'driverName',
              title: 'Driver name',
            },
          ]
        : []),
      {
        name: 'reportedBy',
        title: 'Reported by',
        getCellValue: (row) => get(row, 'creator.name'),
      },
    ],
    [workflow, entityType, timeColumnName]
  );

  const displayTable = (isFetching && isFetchingToAppend) || !isFetching;

  return (
    <div className={classes.root}>
      <SetTitle title={title} />
      <Dialog
        open={error}
        onClose={onResetError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Load failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to load {titleLc}, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <CheckupParamsPicker
        startDate={startDate}
        onStartDateChange={(value) => setStartDate(value.format(dateFormat))}
        endDate={endDate}
        onEndDateChange={(value) => setEndDate(value.format(dateFormat))}
        yardId={yardId}
        onYardIdChange={(event) => setYardId(event.target.value)}
        hideDatePickers={!!fixedDates}
      />
      <div className={classes.searchBoxContainer}>
        <SearchBox
          helperText={<CheckupSearchHelperText />}
          location={location}
          history={history}
          placeholder="Search by tractor, container, chassis, etc."
          onSearch={() => setSearchForceRefresh((previous) => !previous)}
        />
      </div>
      <Paper className={classes.paper}>
        <div className={classes.content}>
          {isFetching && !isFetchingToAppend && <BrandedLoadingIndicator />}
          {displayTable && (
            <>
              <div className={classes.tableContainer}>
                <TableToolbar title={`${title}${typeof totalMatched === 'number' ? `: ${totalMatched}` : ''}`}>
                  {totalMatched > 0 && <CsvExportButton isFetching={exportIsFetching} onClick={handleExportClick} />}
                  <Tooltip title={`Add ${titleSingular}`}>
                    <Fab
                      component={Link}
                      to={addNewUrl}
                      size="small"
                      color="secondary"
                      aria-label={`Add ${titleSingular}`}
                      className={classes.addButton}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </TableToolbar>
                <Grid rootComponent={GridRoot} rows={value} columns={columns}>
                  <SortingState columnSortingEnabled={false} defaultSorting={defaultSorting} />
                  <Table
                    columnExtensions={tableColumnExtensions}
                    containerComponent={TableContainer}
                    tableComponent={TableAutoLayout}
                  />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </div>
              {!isFetching && totalMatched > 0 && value && value.length < totalMatched && (
                <Waypoint scrollableAncestor={window} bottomOffset="-500px" onEnter={() => doSearchRequest(true)} />
              )}
              {isFetching && isFetchingToAppend && <InlineLoadingIndicator />}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    initialYardId: getDefaultYardId(state),
    value: getItems(state) || [],
    isFetching: getIsFetching(state),
    isFetchingToAppend: getIsFetchingToAppend(state),
    error: getError(state),
    totalMatched: getTotalMatchedCount(state),
    exportIsFetching: getExportIsFetching(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    onUnmount: actions.reset,
    onSearch: actions.searchRequest,
    onResetError: actions.resetError,
    onExport: actions.exportRequest,
  })(toJS(CheckupList))
);
