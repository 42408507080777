import { withStyles } from '@material-ui/core/styles';
import logoBlack from 'components/media/logo_black.svg';
import { LOGO_HEIGHT, MOBILE_BREAKPOINT } from 'components/pages/BrandedFormPage';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'none',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      display: 'block',
    },
    paddingBottom: theme.spacing(3),
  },
  logo: {
    display: 'inline-block',
    height: LOGO_HEIGHT,
  },
});

function MobileLogo({ classes }) {
  return (
    <div className={classes.root}>
      <Link to="/" aria-label="Home page">
        <img src={logoBlack} className={classes.logo} alt="omniX logo" />
      </Link>
    </div>
  );
}

export default withStyles(styles)(MobileLogo);
