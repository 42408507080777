import { MenuItem, TextField, Typography } from '@material-ui/core';
import toJS from 'components/common/toJS';
import React from 'react';
import { connect } from 'react-redux';
import { getYards } from 'reducers/auth';

function YardPicker({ yards, dispatch, value, InputLabelProps, ...rest }) {
  return (
    <TextField
      InputLabelProps={{
        shrink: !!value,
        ...InputLabelProps,
      }}
      select
      label="Yard"
      margin="none"
      value={value}
      {...rest}
    >
      {yards.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          <Typography variant="inherit" noWrap>
            {name}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}

function mapStateToProps(state) {
  const yards = getYards(state);
  return {
    yards: (yards && yards.valueSeq()) || [],
  };
}

export default connect(mapStateToProps)(toJS(YardPicker));
