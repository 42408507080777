import * as api from 'api/user';
import { actions as authActions } from 'reducers/auth';
import { actions, types } from 'reducers/profile/details';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, put, takeLeading } from 'redux-saga/effects';

function* updateDetails({ name, timezone }) {
  try {
    const response = yield call(api.updateProfile, name, timezone);

    yield put(actions.updateSuccess(response.data));
    yield put(authActions.userContextUpdated(response.data));
    yield put(snackActions.set('Profile details updated', snackVariants.success));
  } catch (e) {
    yield put(actions.updateFailure(e));
  }
}

export default function* details() {
  yield takeLeading(types.UPDATE_REQUEST, updateDetails);
}
