import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getUserContext } from 'api/auth';
import App from 'components/App';
import SplashScreen from 'components/common/SplashScreen';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from 'reducers';
import { HelmetProvider } from 'react-helmet-async';
import { actions } from 'reducers/auth';
import { CssBaseline } from '@material-ui/core';

const secondaryColor = '#141464';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
  },
  palette: {
    primary: {
      main: '#1E50FF',
    },
    secondary: {
      main: secondaryColor,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 500,
        color: secondaryColor,
        '&:visited': {
          color: secondaryColor,
        },
        '&:hover': {
          color: secondaryColor,
        },
      },
    },
  },
});

function Root() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const response = await getUserContext();
        store.dispatch(actions.loginSuccess(response.data));
        setLoaded(true);
      } catch (error) {
        if (error.response || error.request) {
          setLoaded(true);
        } else {
          throw error;
        }
      }
    }
    if (!loaded) {
      load();
    }
  }, [loaded]);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {loaded ? (
              <App />
            ) : (
              <>
                <CssBaseline />
                <SplashScreen />
              </>
            )}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default Root;
