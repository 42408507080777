import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

const useStyles = makeStyles(() => ({
  secondaryWhite: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const rows = [
  { query: 'TCLU1234567', explanation: 'Movements where tractor, container or chassis serial is "TCLU1234567"' },
  {
    query: 'tractor: 12345 and containerLoad: loaded',
    explanation: 'Movements where tractor serial is "12345" and container is loaded',
  },
  {
    query: 'chassis: *AB12*',
    explanation: 'Movements where chassis serial contains "AB12"',
  },
  {
    query: 'container: TCLU* and containerLoad: empty',
    explanation: 'Movements where container serial starts with "TCLU" and container is empty',
  },
  {
    query: 'containerSeal: *321 OR containerSeal: *987',
    explanation: 'Movements where container seal number ends with "321" or "987"',
  },
  {
    query: 'tractor: *ABC* AND containerSize: 40',
    explanation: 'Movements where tractor serial contains "ABC" and container size is 40 ft',
  },
];

const dashboardRows = [
  { query: 'TCLU1234567', explanation: 'Movements where tractor, container or chassis serial is "TCLU1234567"' },
  { query: 'type: IN AND tractor: 12345', explanation: 'Check ins where tractor serial is "12345"' },
  {
    query: 'chassis: *AB12*',
    explanation: 'Movements where chassis serial contains "AB12"',
  },
  {
    query: 'container: TCLU*',
    explanation: 'Movements where container serial starts with "TCLU"',
  },
  {
    query: 'containerSeal: *321 OR containerSeal: *987',
    explanation: 'Movements where container seal number ends with "321" or "987"',
  },
  {
    query: 'tractor: *ABC* AND containerSize: 40',
    explanation: 'Movements where tractor serial contains "ABC" and container size is 40 ft',
  },
];

function CheckupSearchHelperText({ dashboardMode = false }) {
  const classes = useStyles();
  const items = dashboardMode ? dashboardRows : rows;

  return (
    <>
      You can use the search query to filter movements in many ways. Here are some examples:
      <List dense>
        {items.map(({ query, explanation }) => (
          <ListItem alignItems="flex-start" key={query}>
            <ListItemAvatar>
              <SearchIcon />
            </ListItemAvatar>
            <ListItemText primary={query} secondary={<span className={classes.secondaryWhite}>{explanation}</span>}>
              {query}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default CheckupSearchHelperText;
