import { withStyles } from '@material-ui/core/styles';
import AddMovement from 'components/add/AddMovement';
import CheckInWizard from 'components/add/CheckInWizard';
import CheckOutWizard from 'components/add/CheckOutWizard';
import CheckInList from 'components/checkup/CheckInList';
import CheckOutList from 'components/checkup/CheckOutList';
import CheckupEditor from 'components/checkup/CheckupEditor';
import EntityDeleter from 'components/common/EntityDeleter';
import Dashboard from 'components/dashboard/Dashboard';
import Footer from 'components/footer/Footer';
import ContainersInside from 'components/inside/ContainersInside';
import TrailersInside from 'components/inside/TrailersInside';
import PrimaryNavigation from 'components/menu/PrimaryNavigation';
import NotFound from 'components/pages/NotFound';
import UserEditor from 'components/user/UserEditor';
import UserList from 'components/user/UserList';
import UserProfile from 'components/user/UserProfile';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { getUserRoleName } from 'reducers/auth';
import { Hidden } from '@material-ui/core';
import SSLineList from 'components/ssline/SSLineList';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflowX: 'hidden',
    backgroundColor: '#f1f2f7',
  },
});

function Main({ classes, userRole }) {
  const usersAuthorized = userRole !== 'guard' && userRole !== 'supervisor';
  const ssLinesAuthorized = userRole !== 'guard' && userRole !== 'supervisor';

  return (
    <>
      <div className={classes.root}>
        <PrimaryNavigation />
        <main className={classes.main}>
          <Hidden smUp implementation="css">
            <div className={classes.toolbar} />
          </Hidden>
          <div className={classes.content}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={userRole === 'guard' ? '/add' : '/dashboard'} />} />
              <Route exact path="/add" component={AddMovement} />
              <Route exact path="/add/check-in" component={CheckInWizard} />
              <Route exact path="/add/check-out" component={CheckOutWizard} />
              {userRole !== 'guard' && <Route exact path="/checkins" component={CheckInList} />}
              {userRole !== 'guard' && <Route exact path="/checkouts" component={CheckOutList} />}
              {userRole !== 'guard' && <Route exact path="/dashboard" component={Dashboard} />}
              {userRole !== 'guard' && <Route exact path="/containers-inside" component={ContainersInside} />}
              {userRole !== 'guard' && <Route exact path="/chassis-inside" component={TrailersInside} />}
              {ssLinesAuthorized && <Route exact path="/sslines" component={SSLineList} />}
              {usersAuthorized && <Route exact path="/users" component={UserList} />}
              <Route exact path="/profile" component={UserProfile} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
      <UserEditor />
      <CheckupEditor />
      <EntityDeleter />
    </>
  );
}

function mapStateToProps(state) {
  return {
    userRole: getUserRoleName(state),
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Main)));
