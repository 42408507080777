import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ProfileIcon from '@material-ui/icons/Person';
import { DRAWER_WIDTH } from 'components/menu/PrimaryNavigation';
import UserAvatar from 'components/user/UserAvatar';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { actions, getUserCompany, getUserEmail, getUserName } from 'reducers/auth';

const styles = theme => ({
  infoContainer: {
    overflowX: 'hidden',
    maxWidth: DRAWER_WIDTH - theme.spacing(4),
    margin: theme.spacing(2),
  },
  textContainer: {
    marginLeft: theme.spacing(1),
    overflowX: 'hidden',
  },
});

function UserInfoNavigationMenu({ location, onLogoutClick, classes, email, name, company }) {
  const { pathname } = location;

  return (
    <div>
      <List>
        <ListItem button selected={pathname === '/profile'} component={Link} to="/profile">
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem button component={Link} to="/" onClick={onLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
      <Grid container wrap="nowrap" alignItems="center" className={classes.infoContainer}>
        <Grid item>
          <UserAvatar />
        </Grid>
        <Grid item className={classes.textContainer}>
          <Typography noWrap>
            <strong>{name}</strong>
          </Typography>
          <Typography noWrap>{email}</Typography>
          <Typography noWrap variant="body2" color="textSecondary">
            {company}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    name: getUserName(state),
    company: getUserCompany(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { onLogoutClick: actions.logout })(withStyles(styles)(UserInfoNavigationMenu))
);
