import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
  widget: {
    padding: theme.spacing(2),
  },
  titleIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 20,
    height: 20,
    userSelect: 'none',
    pointerEvents: 'none',
    marginRight: theme.spacing(1),
  },
  widgetTitle: {
    textTransform: 'uppercase',
  },
});

function Widget({ classes, title, icon, titleAction, children }) {
  const IconTag = icon;
  return (
    <div>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item container wrap="nowrap" alignItems="center">
          <IconTag className={classes.titleIcon} />
          <Typography color="textSecondary" variant="subtitle1" className={classes.widgetTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item>{titleAction}</Grid>
      </Grid>
      <Paper className={classes.widget}>
        <Grid container direction="column" spacing={1}>
          {children}
        </Grid>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Widget);
