import { Button, Checkbox, FormControlLabel, Grid, Link as MuiLink, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import PasswordField from 'components/common/PasswordField';
import SetTitle from 'components/common/SetTitle';
import BrandedFormPage, { MOBILE_BREAKPOINT } from 'components/pages/BrandedFormPage';
import MobileLogo from 'components/pages/MobileLogo';
import { parse } from 'qs';
import React, { useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { actions, getAuth, isAuthenticated } from 'reducers/auth';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 272,
    maxWidth: 480,
  },
  error: {
    marginTop: theme.spacing(3),
  },
  form: {
    marginTop: 70,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(2),
    },
  },
  submit: {
    whiteSpace: 'nowrap',
  },
});

function Login({ classes, onSubmit, onUnmount, isFetching, error, authenticated, location }) {
  const { email: initialEmail = '' } = parse(location.search, { ignoreQueryPrefix: true });

  useWillUnmount(onUnmount);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [wasSubmit, setWasSubmit] = useState(false);

  if (authenticated) {
    const { from } =
      location.state && location.state.from && location.state.from.pathname !== '/login'
        ? location.state
        : { from: { pathname: '/' } };
    return <Redirect to={from} />;
  }

  const emailError = wasSubmit && email.trim() === '' ? 'Email is required' : '';
  const passwordError = wasSubmit && password === '' ? 'Password is required' : '';

  function handleSubmit(event) {
    event.preventDefault();
    if (!isFetching) {
      setWasSubmit(true);
      if (email.trim() !== '' && password !== '') {
        onSubmit(email, password, rememberMe);
      }
    }
  }

  return (
    <BrandedFormPage>
      <div className={classes.root}>
        <SetTitle title="Sign in" />
        <MobileLogo />
        <Typography component="h1" variant="h6" gutterBottom>
          Sign in | omniX
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Please enter your details below
        </Typography>
        <Grid
          component="form"
          container
          direction="column"
          wrap="nowrap"
          onSubmit={handleSubmit}
          spacing={4}
          className={classes.form}
        >
          {error && (
            <Grid item>
              <Typography className={classes.error} color="error" variant="subtitle1">
                {error}
              </Typography>
              <Typography variant="subtitle1">
                Forgot the password? You can{' '}
                <MuiLink to={`/forgot-password?email=${email}`} component={Link}>
                  reset it
                </MuiLink>{' '}
                here.
              </Typography>
            </Grid>
          )}
          <Grid item>
            <TextField
              value={email}
              onChange={event => setEmail(event.target.value)}
              disabled={isFetching}
              error={emailError !== ''}
              helperText={emailError}
              label="Email"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus={initialEmail === ''}
              margin="none"
              variant="outlined"
              inputProps={{ required: true }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <PasswordField
              value={password}
              onChange={event => setPassword(event.target.value)}
              disabled={isFetching}
              error={passwordError !== ''}
              helperText={
                passwordError !== '' ? (
                  passwordError
                ) : (
                  <MuiLink to={`/forgot-password?email=${email}`} component={Link}>
                    Forgot password?
                  </MuiLink>
                )
              }
              label="Password"
              id="password"
              name="password"
              autoComplete="current-password"
              margin="none"
              variant="outlined"
              inputProps={{ required: true }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item container direction="column" spacing={1} wrap="nowrap">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isFetching}
                    checked={rememberMe}
                    onChange={(event, checked) => setRememberMe(checked)}
                    value="remember"
                    color="secondary"
                  />
                }
                label="Keep me signed in"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
                <AsyncActionLabel label="Sign in" fetchingLabel="Signing in..." isFetching={isFetching} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </BrandedFormPage>
  );
}

function mapStateToProps(state) {
  const auth = getAuth(state);
  return {
    isFetching: auth.get('isFetching'),
    error: auth.get('error'),
    authenticated: isAuthenticated(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { onSubmit: actions.loginRequest, onUnmount: actions.resetFlags })(withStyles(styles)(Login))
);
