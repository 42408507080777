import { apiPath as userApiPath } from 'api/user';
import { filter } from 'lodash';
import { geSSLineByCode, getYardNameById } from 'reducers/auth';
import store from 'reducers';

export const WORKFLOW_XPO = 'xpo';
export const WORKFLOW_ROADRUNNER = 'roadrunner';

export const TYPE_CHECK_IN = 'CHECK_IN';
export const TYPE_CHECK_OUT = 'CHECK_OUT';
export const TYPE_USER = 'USER';
export const TYPE_SSLINE = 'SSLINE';

export const LOAD_TYPE_LOADED = 'loaded';
export const LOAD_TYPE_EMPTY = 'empty';

export const checkInApiPath = '/apigw/v1/checkin';
export const checkOutApiPath = '/apigw/v1/checkout';
export function getApiPathForType(entityType) {
  switch (entityType) {
    case TYPE_CHECK_IN:
      return checkInApiPath;
    case TYPE_CHECK_OUT:
      return checkOutApiPath;
    case TYPE_USER:
      return userApiPath;
    default:
      throw new Error(`Unknown entity type: ${entityType}`);
  }
}

export function getTitleForType(entityType) {
  switch (entityType) {
    case TYPE_CHECK_IN:
      return 'check in';
    case TYPE_CHECK_OUT:
      return 'check out';
    case TYPE_USER:
      return 'user';
    case TYPE_SSLINE:
      return 'SS line';
    default:
      throw new Error(`Unknown entity type: ${entityType}`);
  }
}

export const truckChecklistDefinition = [
  { name: 'Lights (all lights must be operational)', key: 'lights' },
  { name: 'Conspicuity', key: 'conspicuity' },
  { name: 'Tires & wheels', key: 'tiresAndWheels' },
  { name: 'Fire extinguisher', key: 'extinguisher' },
];
export const trailerChecklistDefinition = [
  { name: 'Lights (all lights must be operational)', key: 'lights' },
  { name: 'Chassis tires & wheels', key: 'tiresAndWheels' },
  { name: 'Conspicuity / reflectors', key: 'conspicuity' },
  { name: 'License plate / registration', key: 'licensePlate' },
  { name: 'FHWA inspection sticker', key: 'fhwa' },
  { name: 'Mud flaps', key: 'mudFlaps' },
  { name: 'Twist lock, push pins and securement devices', key: 'securement' },
];
export const brakesAndAxlesChecklist = [
  { name: 'LF', key: 'lf', denominator: 8 },
  { name: 'RF', key: 'rf', denominator: 8 },
  { name: 'LR', key: 'lr', denominator: 8 },
  { name: 'RR', key: 'rr', denominator: 8 },
];
export const tiresChecklist = [
  { name: 'LOF', key: 'lof', denominator: 32 },
  { name: 'ROF', key: 'rof', denominator: 32 },
  { name: 'LIF', key: 'lif', denominator: 32 },
  { name: 'RIF', key: 'rif', denominator: 32 },
  { name: 'LIR', key: 'lir', denominator: 32 },
  { name: 'RIR', key: 'rir', denominator: 32 },
  { name: 'LOR', key: 'lor', denominator: 32 },
  { name: 'ROR', key: 'ror', denominator: 32 },
];

export function createSuccessfulChecklist(checklistDefinition) {
  const checklist = { autoGenerated: true };
  checklistDefinition.forEach((item) => {
    checklist[item.key] = true;
  });
  return checklist;
}

export function getFailedCheckListItems(checklist, checklistDefinition) {
  return filter(checklistDefinition, (item) => {
    const value = checklist[item.key];
    return value === false;
  }).map((item) => item.name);
}

export function resolveSSLineName(serial) {
  if (!serial || serial.length < 4) {
    return 'Unknown';
  }

  const ssLine = geSSLineByCode(store.getState(), serial.substring(0, 4));
  return (ssLine && ssLine.get('name')) || 'Unknown';
}

export function resolveYardNameById(yardId) {
  return getYardNameById(store.getState(), yardId);
}

const garbageSerials = ['NONE', 'N/A', 'NO', '-', '0000'];
export const noValue = '—';

function getVisitStatus(entity, statusField, serialField, serialMinLength) {
  if (entity.name === TYPE_CHECK_OUT) {
    return noValue;
  }
  const serial = entity[serialField];
  if (!serial || serial.length < serialMinLength || garbageSerials.includes(serial)) {
    return noValue;
  }

  switch (entity[statusField]) {
    case true:
      return 'Open';
    case false:
      return 'Closed';
    default:
      return noValue;
  }
}

export function getTruckVisitStatus(entity) {
  return getVisitStatus(entity, 'truckInside', 'truckSerial', 3);
}

export function getTrailerVisitStatus(entity) {
  return getVisitStatus(entity, 'trailerInside', 'trailerSerial', 4);
}

export function getContainerVisitStatus(entity) {
  return getVisitStatus(entity, 'containerInside', 'containerSerial', 5);
}

export function prepareListItems(items) {
  if (!items || !items.length) {
    return items;
  }
  items.forEach((item) => {
    item.truckChecklist = item.truckChecklist || createSuccessfulChecklist(truckChecklistDefinition);
    item.trailerChecklist = item.trailerChecklist || createSuccessfulChecklist(trailerChecklistDefinition);
  });
  return items;
}
