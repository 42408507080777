import { saveResponseAsFile } from 'api/utils';
import axios from 'axios';
import { getUserTimezone } from 'components/common/momentUtils';
import { getItems } from 'reducers/checkup/list';
import { actions, types } from 'reducers/dashboard/checkup';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

function* prepareSearchParams(startDate, endDate, yardId, query, append) {
  const limit = 20;
  const sort = [{ name: 'createdAt', order: 'DESC' }];
  const filter = {
    and: {
      items: [
        {
          range: {
            name: 'createdAt',
            params: {
              gte: startDate,
              lte: endDate,
            },
          },
        },
      ],
    },
  };
  if (yardId) {
    filter.and.items.push({ term: { name: 'yardId', value: yardId } });
  }

  const params = { query, filter, limit, sort };

  const items = yield select((state) => getItems(state));
  if (append && items && items.size > 0) {
    params.tieBreakerId = items.last().get('id');
    params.searchAfter = [new Date(items.last().get('createdAt')).getTime()];
  }
  return params;
}

function* exportCheckups({ startDate, endDate, yardId, query = '', filename }) {
  try {
    const searchParams = yield call(prepareSearchParams, startDate, endDate, yardId, query, false);
    const attributes = {
      yardId,
      timezone: getUserTimezone(),
    };

    const response = yield call(axios.post, `/apigw/v1/checkup/actions/export/invoke`, { searchParams, attributes });
    saveResponseAsFile(response, filename);
    yield put(actions.exportSuccess());
  } catch (e) {
    yield put(actions.exportFailure(e));
    yield put(snackActions.set('Failed to export data as CSV', snackVariants.error));
  }
}

function* getStats({ startDate, endDate, yardId }) {
  try {
    const searchParams = yield call(prepareSearchParams, startDate, endDate, yardId, '', false);

    const response = yield call(axios.post, '/apigw/v1/checkup/dashboardStats', searchParams);
    yield put(actions.statsSuccess(response.data));
  } catch (e) {
    yield put(actions.statsFailure(e));
  }
}

function* searchCheckups({ startDate, endDate, yardId, query = '', append }) {
  try {
    const params = yield call(prepareSearchParams, startDate, endDate, yardId, query, append);

    const response = yield call(axios.post, `/apigw/v1/checkup/search`, params);
    yield put(actions.searchSuccess(response.data.items, response.data.paging, append));
  } catch (e) {
    yield put(actions.searchFailure(e));
  }
}

export default function* list() {
  yield all([
    takeLatest(types.SEARCH_REQUEST, searchCheckups),
    takeLatest(types.STATS_REQUEST, getStats),
    takeEvery(types.EXPORT_REQUEST, exportCheckups),
  ]);
}
