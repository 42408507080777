import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import ItemIcon from '@material-ui/icons/ArrowRight';
import { ERROR_COLOR } from 'components/style/colors';
import React from 'react';

const styles = () => ({
  errorItemIcon: {
    color: ERROR_COLOR,
  },
  errorItemText: {
    padding: 0,
  },
});

function CheckupErrorsDialog({ classes, title, open, errors, onClose, ...rest }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">{title} is incomplete</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To save a {title.toLowerCase()} please complete the following items:
        </DialogContentText>
        {errors &&
          Object.keys(errors).map(stepName => {
            const stepErrors = errors[stepName];
            if (!stepErrors.length) {
              return null;
            }

            return (
              <List key={stepName}>
                <ListItem disableGutters>
                  <ListItemText inset={false} primary={<strong>{stepName}</strong>} />
                </ListItem>
                {stepErrors.map(error => (
                  <List key={error} component="div" disablePadding>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon className={classes.errorItemIcon}>
                        <ItemIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.errorItemText} inset={false} primary={error} />
                    </ListItem>
                  </List>
                ))}
              </List>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="secondary">
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(CheckupErrorsDialog);
