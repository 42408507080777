export function saveResponseAsFile(response, filename) {
  const type = response.headers['content-type'];

  let blob;
  try {
    blob = new File([response.data], filename, { type });
  } catch {
    blob = new Blob([response.data], { type });
  }

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // use HTML5 a[download] attribute to specify filename
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      const reader = new FileReader();
      reader.onload = () => (window.location.href = reader.result);
      reader.readAsDataURL(blob);
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
}
