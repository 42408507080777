import { SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { noValue, resolveYardNameById, WORKFLOW_XPO } from 'components/checkup/utils';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import CsvExportButton from 'components/common/CsvExportButton';
import { GridRoot, TableContainer } from 'components/common/Grid';
import { momentUserTz } from 'components/common/momentUtils';
import TableAutoLayout from 'components/common/TableAutoLayout';
import TableToolbar from 'components/common/TableToolbar';
import toJS from 'components/common/toJS';
import useYardWorkflow from 'components/common/useYardWorkflow';
import { ERROR_COLOR } from 'components/style/colors';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { actions, getError, getExportIsFetching, getIsFetching, getItems } from 'reducers/inside/trailers';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 0,
  },
  tableContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    minHeight: 200,
    '& > div > div:nth-child(3)': {
      maxHeight: 'unset',
    },
  },
}));

const defaultSorting = [{ columnName: 'createdAt', direction: 'asc' }];

function TrailersInsideList({ yardId, query, isFetching, exportIsFetching, error, onResetError, onExport, value }) {
  const classes = useStyles();
  const handleExportClick = useCallback(() => {
    if (!exportIsFetching) {
      const yardName = resolveYardNameById(yardId);
      const filename = `Chassis in yard - ${yardName}.csv`;
      onExport(yardId, query, filename);
    }
  }, [exportIsFetching, yardId, query, onExport]);
  const workflow = useYardWorkflow(yardId);
  const columns = useMemo(
    () => [
      { name: 'truckSerial', title: 'Tractor' },
      { name: 'trailerSerial', title: 'Chassis' },
      {
        name: 'trailerSize',
        title: 'Chassis size',
        getCellValue: (row) => (row.trailerSize ? `${row.trailerSize} ft` : noValue),
      },
      {
        name: 'createdAt',
        title: 'Entry time',
        getCellValue: (row) => {
          const value = momentUserTz(row.createdAt).format('MM/DD/YYYY HH:mm');
          const ageThresholdDays = workflow === WORKFLOW_XPO ? 3.0 : 7.0;
          return value ? (
            <div
              style={{
                color:
                  momentUserTz().diff(momentUserTz(value, 'MM/DD/YYYY HH:mm:ss'), 'days', true) > ageThresholdDays
                    ? ERROR_COLOR
                    : undefined,
              }}
            >
              {value}
            </div>
          ) : null;
        },
      },
      {
        name: 'reportedBy',
        title: 'Reported by',
        getCellValue: (row) => get(row, 'creator.name'),
      },
    ],
    [workflow]
  );

  return (
    <>
      <Dialog
        open={error}
        onClose={onResetError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Load failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to load chassis, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        {isFetching ? (
          <BrandedLoadingIndicator />
        ) : (
          <div className={classes.tableContainer}>
            <TableToolbar title={`Chassis in yard${value?.length ? `: ${value.length}` : ''}`}>
              {value?.length > 0 && <CsvExportButton isFetching={exportIsFetching} onClick={handleExportClick} />}
            </TableToolbar>
            <Grid rootComponent={GridRoot} rows={value} columns={columns}>
              <SortingState columnSortingEnabled={false} defaultSorting={defaultSorting} />
              <Table containerComponent={TableContainer} tableComponent={TableAutoLayout} />
              <TableHeaderRow showSortingControls />
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    value: getItems(state) || [],
    isFetching: getIsFetching(state),
    exportIsFetching: getExportIsFetching(state),
    error: getError(state),
  };
}

export default connect(mapStateToProps, {
  onResetError: actions.resetError,
  onExport: actions.exportRequest,
})(toJS(TrailersInsideList));
